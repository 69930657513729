import { getAccessToken } from "../../auth/verifyAuth";

async function getAllTenants() {
    let oldAllTenants = localStorage.getItem("allTenants");
    if (oldAllTenants) {oldAllTenants = JSON.parse(oldAllTenants);}

    if (!oldAllTenants || !oldAllTenants.data || ((new Date(oldAllTenants.time)).getDate() !== (new Date()).getDate())) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/tenants",
        {
            method: "GET",
            headers: {
                "my-Authorization": `Bearer ${accessToken}`
            }
        }
    )
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then((res) => {
            if (res) {
                localStorage.setItem("allTenants", JSON.stringify({"data" : res, "time" : new Date()}))
                return res;
            }
        });
    } else {
        return oldAllTenants.data;
    }
}

async function getTenant() {
    let oldTenant = localStorage.getItem("tenant");
    if (oldTenant) {oldTenant = JSON.parse(oldTenant);}

    if (!oldTenant || !oldTenant.data || ((new Date(oldTenant.time)).getDate() !== (new Date()).getDate())) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/tenant",
        {
            method: "GET",
            headers: {
                "my-Authorization": `Bearer ${accessToken}`
            }
        }
    )
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then((res) => {
            if (res) {
                localStorage.setItem("tenant", JSON.stringify({"data" : res, "time" : new Date()}))
                return res;
            }
        });
    } else {
        return oldTenant.data;
    }
}

export { getAllTenants, getTenant };
