import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { ChatBubbleBottomCenterTextIcon, ClockIcon, CircleStackIcon, HomeIcon, NewspaperIcon, PlusIcon, UsersIcon } from "@heroicons/react/24/outline";
import { getAllTenants, getTenant } from "../../utils/requests/tenant";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MenuItem({ name, path, t, Logo, isExtern }) {
  let navigate = useNavigate();
  let location = useLocation();

  return <div
    key={name}
    onClick={() => {
      if (isExtern) {
        window.open(path, "_blank");
      } else {
        navigate(path);
      }
    }}
    className={classNames(
      path === location.pathname
        ? "bg-gray-100 text-gray-900 font-bold"
        : "text-gray-600 hover:bg-gray-50 hover:text-gray-900 font-semibold",
      "group flex items-center px-2 py-1 text-sm  rounded-md cursor-pointer"
    )}
  >
    <Logo
      className={classNames(
        path === location.pathname
          ? "text-gray-900"
          : "text-gray-500 group-hover:text-gray-500",
        "mr-2 flex-shrink-0 h-4 w-4"
      )}
      aria-hidden="true"
    />
    {t(name)}
    {isExtern && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 text-gray-400 ml-2">
      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
    </svg>}

  </div>
}

function Aside({ navigation, t, owner }) {
  let navigate = useNavigate();
  const [client, setClient] = useState(null);

  useEffect(()=>{
    getAllTenants().then((tenants) => {
      if (tenants && tenants.length) {
        setClient(tenants[0]);
      } else {
        getTenant().then((tenant) => {
          setClient(tenant);
        });
      }
    });
  },[])

  return (
    <div className="flex w-full md:w-64 flex-col fixed inset-y-0">
      <div className="flex-1 flex flex-col min-h-0 bg-white border-r">
        <div
          className="flex items-center my-6 flex-shrink-0 pl-5 cursor-pointer"
          onClick={() => {
            navigate("");
          }}
        >
          <span className="sr-only">Exaduo</span>
          <picture>
            <source
              srcSet={process.env.PUBLIC_URL + "/assets/icons/logo.webp"}
              type="image/webp"
            />
            <source
              srcSet={process.env.PUBLIC_URL + "/assets/icons/logo.png"}
              type="image/jpeg"
            />
            <img
              className="h-5 w-auto"
              src={process.env.PUBLIC_URL + "/assets/icons/logo.png"}
              alt="Logo"
            />
          </picture>
        </div>
        <div className="flex-1 flex flex-col overflow-y-auto">
          <div className="flex-1 px-4 py-4 pt-0 space-y-1">
            <div className="mb-4 mt-1">
              <MenuItem name="Dashboard" path="/" t={t} Logo={HomeIcon} />
            </div>
            <div className="border-t border-gray-200 mt-4 py-4">
              <div className="text-xs uppercase text-gray-400 mb-2 flex justify-between font-bold">{t("Uses")}<div><PlusIcon className="h-4" /></div></div>
              {navigation.map((item, k) => <MenuItem key={k} name={item.name} path={item.path} t={t} Logo={item.logo} />)}
            </div>
            <div className="border-t border-gray-200 mt-4 py-4">
              <div className="text-xs uppercase text-gray-400 mb-2 font-bold">{t("Administration")}</div>
              <MenuItem name={t("Bills")} path={"/bills"} t={t} Logo={NewspaperIcon} />
              <MenuItem name={t("TMA and Project")} path={"/tma-and-projects"} t={t} Logo={ClockIcon} />
              <MenuItem name={t("Users")} path={"/users"} t={t} Logo={UsersIcon} />
              <MenuItem name={t("Documentation")} path={"https://news.exaduo.fr/"} t={t} Logo={CircleStackIcon} isExtern={true} />
              {owner && <MenuItem name={t("Commercial contact")} path={"https://meetings.hubspot.com/" + owner.id} t={t} Logo={ChatBubbleBottomCenterTextIcon} isExtern={true} />}
            </div>
          </div>
          <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
            {client && <div onClick={()=>{navigate("informations")}}>
              <div className="font-bold text-md cursor-pointer">{client.name}</div>
              <div className="font-light text-xs text-gray-500">{client.idTenant}</div>
            </div>}
            {/* <div className="flex-shrink-0 w-full group block">
              <div className="flex items-center cursor-pointer" onClick={() => {
                if (location.pathname === "/") {
                  logout();
                } else {
                  navigate("/");
                  logout();
                }
              }}>
                {photo ? (<><div>
                  <img
                    className="inline-block h-9 w-9 rounded-full"
                    src={photo}
                    alt=""
                  />
                </div>
                  <div className="ml-3 cursor-pointer">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{name}</p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{t("Logout")}</p>
                  </div>
                </>
                ) : (<><div>
                  <ArrowLeftOnRectangleIcon
                    className="text-gray-500 hover:text-gray-700 flex-shrink-0 h-6 w-6"
                  />
                </div>
                  <div className="ml-3 cursor-pointer">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{name}</p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">{t("Logout")}</p>
                  </div>
                </>)}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div >
  );
}

export default withTranslation()(Aside);
