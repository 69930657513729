export default function columnsTMA(t) {
    var col = [
        {
            key: "date",
            visible: true,
            label: t("Date"),
            exportableCSV: true,
            id: "date",
            width: 300,
            format: (row) => {
                var date = new Date(row.date)
                return <span>
                    {(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())}
                </span>
            }
        },
        {
            key: "heures",
            visible: true,
            label: t("Hours"),
            exportableCSV: true,
            id: "heures",
            width: 300,
            format: (row) =>
                <span className="text-gray-600">
                    {row.heures}h
                </span>,
        },
        {
            key: "description",
            visible: true,
            label: t("Description"),
            exportableCSV: true,
            id: "description",
            width: 300,
            format: (row) =>
                <span className="text-gray-600">
                    {row.description}
                </span>,
        }
    ];

    return col
}