import Card from "../Card/Card";

function ListCardSkeleton({ length }) {
    return Array.apply(0, Array(length)).map(function (_, b) { return b + 1; }).map(l => <div className="mb-2 animate-pulse"><Card key={l}>
        <div className="grid grid-cols-3">
            <div className="col-span-2">
                <div className="font-normal"><div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-64"></div></div>
                <div className="text-gray-400 mt-1"><div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-16"></div></div>
            </div>
            <div>
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-64"></div>
            </div>
        </div>
    </Card></div>)
}

export default ListCardSkeleton;