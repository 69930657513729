export default function UserCard({ active, displayName, subtitle, imageUrl, buttons }) {
  return (
    <div className={active === false ? "col-span-1 bg-gray-200 rounded-lg divide-y divide-gray-300 border" : "col-span-1 bg-white rounded-lg divide-y divide-gray-200 border"}>
      <div className="w-full flex items-center justify-between p-6 space-x-6">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="text-gray-900 text-sm font-medium truncate">
              {displayName}
            </h3>
          </div>
          <p className="mt-1 text-gray-500 text-sm truncate">{subtitle}</p>
        </div>
        {imageUrl && (
          <img
            className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
            src={imageUrl}
            alt=""
          />
        )}
      </div>
      {buttons && buttons.length ? <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          {buttons.map((b, key) => <div key={key} className="w-full flex">{b}</div>)}
        </div>
      </div> : null}
    </div>
  );
}
