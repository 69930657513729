import SectionTitle from "../Text/SectionTitle";

export default function Card({ title, children, className, actions, noBorder }) {
  return (
    <div className={noBorder ? "flow-root" : "border-dashed border rounded-xl p-4 flow-root"}>
      {title && <div><SectionTitle actions={actions}>{title}</SectionTitle></div>}
      <div className={title ? "flow-root mt-3" : "flow-root"} data-testid="card-content">
        {children}
      </div>
    </div>)
}