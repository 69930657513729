import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

function SecureScoreDoughnut({ value, loading }) {
  const data = {
    datasets: [{
      data: value ? [value, Math.round(100 - value)] : [0, 0],
      backgroundColor: loading ? ['#e5e7eb',
        '#fff'] : [
        '#600AFF',
        '#fff'
      ]
    }]
  }

  return value ? <div className={loading ? "animate-pulse relative" : "relative"}>
    {!loading && <div className="absolute" style={{ fontSize: "23px", fontWeight: "900", paddingTop: "41.5%", paddingLeft: "37.5%", color: '#600AFF' }}>{value}%</div>}
    <Doughnut data={data} height={170} width={170} />
  </div> : null
}

export default SecureScoreDoughnut