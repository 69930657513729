import React, { Fragment, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

// import components
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import TableExpandableLicences from "../../../utils/tables/TableExpandableLicences";
import SectionTitle from "../../../components/Text/SectionTitle";

function LicenceType({ t, licencesAPI, title }) {
    const [licences, setLicences] = useState([]);
    const [defaultLicences, setDefaultLicences] = useState([]);
    const [listStatus, setListStatus] = useState([]);

    useEffect(() => {
        if (licencesAPI && licencesAPI.length) {
            setLicences(licencesAPI.filter(l => l.subscriptionStatus === "Active"));
            var status = licencesAPI.map(l => l.subscriptionStatus).filter((value, index, self) => self.indexOf(value) === index).map(c => { return { value: c, label: c, actif: false } })
            var index = status.findIndex(s => s.value === "Active");
            status[index].actif = true;
            setListStatus(status);
            setDefaultLicences(licencesAPI);
        }

    }, [licencesAPI]);

    return licencesAPI && licencesAPI.length ? (<div>
        <SectionTitle actions={[<div className="flex justify-end">
            <Popover.Group className="flex items-baseline space-x-2 sm:space-x-8">
                <Popover
                    as="div"
                    key="status"
                    id={`desktop-menu-status`}
                    className="relative z-10 inline-block text-left"
                >
                    <div>
                        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                            <span>{t("Status")}</span>
                            {listStatus && listStatus.filter(opt => opt.actif).length > 0 ? (
                                <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                                    {listStatus.filter(opt => opt.actif).length}
                                </span>
                            ) : null}
                            <ChevronDownIcon
                                className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                            />
                        </Popover.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4">
                            <form className="space-y-4">
                                {listStatus.map((option, optionIdx) => (
                                    <div key={option.value} className="flex items-center cursor-pointer">
                                        <input
                                            onChange={() => {
                                                var filterLocal = listStatus;
                                                var optionLocal = filterLocal[optionIdx];
                                                optionLocal.actif = !optionLocal.actif;
                                                setListStatus(filterLocal);
                                                setLicences(defaultLicences.filter(l => filterLocal.filter(fl => fl.actif).map(f => f.value).includes(l.subscriptionStatus)));
                                            }}
                                            id={`filter-status-${optionIdx}`}
                                            name={`status`}
                                            checked={option.actif}
                                            type="checkbox"
                                            className="h-4 w-4 border-gray-300 rounded text-indigo-600 cursor-pointer"
                                        />
                                        <label
                                            htmlFor={`filter-status-${optionIdx}`}
                                            className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                                        >
                                            {option.label}
                                        </label>
                                    </div>
                                ))}
                            </form>
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </Popover.Group></div>]}><div className="ml-5">{t(title)}</div></SectionTitle>
        <div className="mt-4">
            <TableExpandableLicences data={licences} />
        </div>
    </div>
    ) : null;
}

export default withTranslation()(LicenceType);
