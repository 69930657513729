import { InformationCircleIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import Card from "../../../components/Card/Card";
import ListFunctionnalitiesSkeleton from "../../../components/LoadingComponent/ListFunctionnalitiesSkeleton";
import SecureScoreDoughnut from "../../../utils/chart/SecureScoreDoughnut";
import { getAllLicences, getRecommandation } from "../../../utils/requests/licenses";
import { getSecureScore } from "../../../utils/requests/secureScore";
import { getAllTenants, getTenant } from "../../../utils/requests/tenant";
import ListFonctionnalitiesSecurity from "../../../utils/tables/ListFonctionnalitiesSecurity";

function Security({ t }) {
  const [loading, setLoading] = useState([0, 0, 0]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [recommandations, setRecommandations] = useState([]);
  const [fullSecureScore, setFullSecureScore] = useState({});


  useEffect(() => {
    getAllTenants().then((tenants) => {
      if (tenants && tenants.length) {
        setFullSecureScore(tenants[0].secureScore)
        setLoading(l => { l[0] = 1; return l })
      } else {
        getTenant().then((tenant) => {
          setLoading(l => { l[0] = 1; return l })
          setFullSecureScore(tenant.secureScore)
        });
      }
    });
    getAllLicences().then(licences => {
      setLoading(l => { l[1] = 1; return l })
      if (licences && licences.length) {
        var listSub = [];
        licences.forEach(licence => {
          if (licence.products && licence.products.length > 0 && !licence.subscriptionOfferName.includes("tudent") && !licence.subscriptionOfferName.includes("lumni") && licence.subscriptionStatus === "Active") {
            licence.products.forEach(p => {
              if (p && p != null && !listSub.find(s => s.id === p.id)) {
                listSub.push(p);
              }
            });
          }
        })
        setSubscriptions(listSub);
      }
    });
    getRecommandation().then(reco => {
      setLoading(l => { l[2] = 1; return l }); 
      setRecommandations(reco)
    })
    getSecureScore().then(res => {
      setFullSecureScore(res);
    })
  }, []);

  return (
    <div>
      <div className="pb-3">
        <div className="px-5 xl:px-0 py-10 xl:py-2 grid xl:grid-cols-5 xl:space-x-5">
          <div className="xl:mb-2 mb-6">
            <Card title={<div className="flex">
              {t("Secure Score")}
              <div data-tip data-for="appInfo">
                <InformationCircleIcon className="ml-1 mt-2 h-4 text-gray-500" />
              </div>
              <ReactTooltip id="appInfo" type="light">
                <span>{t("Description secure score")}</span>
              </ReactTooltip>
            </div>}>
              <div className="flex justify-center cursor-pointer" style={{ height: "170px" }} onClick={() => { window.open("https://security.microsoft.com/securescore?viewid=actions", '_blank'); }}>
                {loading[0] === 1 ? <SecureScoreDoughnut value={fullSecureScore.global} /> : <SecureScoreDoughnut value={100} loading={true} />}
              </div>
            </Card>
          </div>
          <div className="xl:col-span-4">
            {loading[1] && loading[2] ? <ListFonctionnalitiesSecurity subscriptions={subscriptions} recommandations={recommandations} secureScore={fullSecureScore} />
              : <ListFunctionnalitiesSkeleton />}
          </div>
        </div>
        {/* <div className="px-5 xl:px-0 xl:pb-6">
            <InformationAlertWithLink text={t("Si votre nombre d'utilisateur qualifié est différent du nombre de licences, les licences sélectionnées ci-dessus peuvent correspondre uniquement à des licences de test.")}/>
          </div> */}
      </div>
    </div>
  );
}

export default withTranslation()(Security);
