import React from "react";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/outline";
import { withTranslation } from "react-i18next";
import { logout } from "../../auth/verifyAuth";
import { useLocation, useNavigate } from "react-router";
import SelectMenu from "../../components/Inputs/SelectMenu";

function Header({ t, photo, tenants, currentTenant}) {

  function forceAuthentWithTenant(idTenant)
  {
    logout()
  }

  let navigate = useNavigate();
  let location = useLocation();

  return (
    <div className="md:sticky md:top-0 md:z-10 md:h-16 md:bg-white md:shadow hidden md:flex">
      <div className="px-4 flex justify-end w-full">
        <div className="mx-4 flex items-center md:ml-6">
          {tenants && tenants.length > 1 ? <div>
            <SelectMenu options={tenants.map(ten => {
              ten.title = ten.domain;
              ten.description = t("ID Tenant : ") + ten.idTenant;
              return ten;
            })} currentTenant={currentTenant} onClick={forceAuthentWithTenant}/>
          </div> : ""}
          <div className="ml-3 relative">
            <div>
              <div
                onClick={() => {
                  if (location.pathname === "/") {
                    logout();
                  } else {
                    navigate("/");
                    logout();
                  }
                }}
                className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {photo ? (
                  <img className="h-8 w-8 rounded-full" src={photo} alt="" />
                ) : (
                  <ArrowLeftOnRectangleIcon
                    className="text-gray-500 hover:text-gray-300 flex-shrink-0 h-6 w-6"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Header);
