import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function Graph({ t, height, width }) {

    return <div className="animate-pulse"><Bar data={{
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: t("Consumption"),
                data: Array.from({ length: 7 }, () => Math.floor(Math.random() * 40)),
                backgroundColor: "#e5e7eb",
            }
        ],
    }} options={{
        responsive: true,
        scales: {
            x: {
                stacked: true
            },
            y: {
                stacked: true,
                callback: function (value) {
                    return `${value}€`
                }
            }
        },
        plugins: {
            legend: null,
        },
    }} height={height} width={width} /></div>;

}

export default withTranslation()(Graph);
