import { getAccessToken } from "../../auth/verifyAuth";

async function getAllLicences() {
    let oldLicenses = localStorage.getItem("licenses");
    if (oldLicenses) { oldLicenses = JSON.parse(oldLicenses); }

    if (!oldLicenses || !oldLicenses.data || ((new Date(oldLicenses.time)).getDate() !== (new Date()).getDate())) {
        var accessToken = await getAccessToken();
        return fetch(window.location.origin + "/api/v1.0/licences",
            {
                method: "GET",
                headers: {
                    "my-Authorization": `Bearer ${accessToken}`
                }
            }
        )
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then((res) => {
                if (res) {
                    localStorage.setItem("licenses", JSON.stringify({ "data": res, "time": new Date() }))
                    return res;
                }
            })
    } else {
        return oldLicenses.data
    }
}

async function getRecommandation() {
    let oldSubscriptions = localStorage.getItem("recommandation/csp");
    if (oldSubscriptions) { oldSubscriptions = JSON.parse(oldSubscriptions); }

    if (!oldSubscriptions || !oldSubscriptions.data || ((new Date(oldSubscriptions.time)).getDate() !== (new Date()).getDate())) {
        var accessToken = await getAccessToken();
        return fetch(
            window.location.origin + "/api/v1.0/recommandation/csp",
            {
                method: "GET",
                headers: {
                    "my-Authorization": `Bearer ${accessToken}`,
                }
            }
        ).then(res => {
            if (res.ok)
                return res.json()
        }).then(res => {
            localStorage.setItem("recommandation/csp", JSON.stringify({ "data": res, "time": new Date() }))
            return res
        })
    } else {
        return oldSubscriptions.data;
    }
}



export { getAllLicences, getRecommandation };
