import { DevicePhoneMobileIcon, UserIcon, UsersIcon } from "@heroicons/react/24/outline";
import { withTranslation } from "react-i18next";

function TableFunctionnalitiesSkeleton({ length, t }) {
    return <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 animate-pulse">
        {Array.apply(0, Array(length)).map(function (_, b) { return b + 1; }).map((d, k) => {
            var className = "p-4 rounded-lg border border-dashed flex bg-white";
            var iconslist = ["device", "identity", "other"];
            var iconName = iconslist[Math.floor(Math.random() * iconslist.length)];
            return <div key={k} className={className}>
                {iconName === "device" ?
                    <DevicePhoneMobileIcon className="p-2 rounded-lg col-span-1 h-12 w-12 min-w-12  bg-gray-200 text-gray-400" />
                    : (iconName === "identity" ? <UserIcon className="p-2 rounded-lg col-span-1 h-12 w-12 min-w-12  bg-gray-200 text-gray-400" />
                        : <UsersIcon className="p-2 rounded-lg col-span-1 h-12 w-12 min-w-12  bg-gray-200 text-gray-400" />)
                }
                <div className="ml-4 col-span-11 md:col-span-5 xl:col-span-11">
                    <div style={{ verticalAlign: "middle" }} className="font-medium text-gray-400 pt-3"><div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-64"></div>
                        <div className="flex gap-1 mt-1">{Array.apply(0, Array(2)).map(function (_, b) { return b + 1; }).map((t, k) => <div key={k} className="bg-gray-200 rounded-full px-2 text-sm text-gray-600"><div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div></div>
                        )}</div>
                    </div>
                </div>
            </div>
        })}</div>
}
export default withTranslation()(TableFunctionnalitiesSkeleton)
