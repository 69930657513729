import { getAccessToken, getAccessTokenGraph } from "../../auth/verifyAuth";


async function getSecureScore() {
  var accessToken = await getAccessTokenGraph();

  return fetch("https://graph.microsoft.com/v1.0/security/secureScores?$top=1", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
  }).then(secureScore => {
    if (secureScore) {
      return getSecureScoreControlProfiles().then(secureScoreControlProfiles => {
        var allScores = handleScores(secureScore.value, secureScoreControlProfiles.value)
        setSecureScore(allScores);
        return allScores;
      })
    } else {
      return null;
    }
  });
}

async function setSecureScore(secureScore) {
  var accessToken = await getAccessToken();
  return fetch(window.location.origin + "/api/v1.0/consumption",
    {
      method: "PATCH",
      headers: {
        "my-Authorization": `Bearer ${accessToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(secureScore)
    }
  )
    .then((res) => {
      if (res.ok) {
        return true
      } else {
        return false
      }
    })
}


async function getSecureScoreControlProfiles() {
  var accessToken = await getAccessTokenGraph();

  return fetch("https://graph.microsoft.com/v1.0/security/secureScoreControlProfiles", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
}

function handleScores(secureScore, secureScoreControlProfiles) {
  var scores = {
    "identity": 0,
    "device": 0,
    "collaboration": 0,
    "global": 0
  }

  // IDENTITY
  var scoreId = secureScore.reduce((tot, v) => tot + v.controlScores.reduce((t, cs) => cs.controlCategory === "Identity" ? t + cs.score : t, 0), 0);
  var maxScoreId = secureScoreControlProfiles.reduce((tot, v) => v.controlCategory === "Identity" && !v.id.startsWith('MDA_') ? tot + v.maxScore : tot, 0);
  scores.identity = maxScoreId ? Math.round(scoreId * 100 / maxScoreId) : 0;

  // DEVICE
  var scoreDev = secureScore.reduce((tot, v) => tot + v.controlScores.reduce((t, cs) => cs.controlCategory === "Device" ? t + cs.score : t, 0), 0);
  var maxScoreDev = secureScoreControlProfiles.reduce((tot, v) => v.controlCategory === "Device" && !v.id.startsWith('MDA_') ? tot + v.maxScore : tot, 0);
  scores.device = maxScoreDev ? Math.round(scoreDev * 100 / maxScoreDev) : 0;

  // COLLABORATION
  var scoreCol = secureScore.reduce((tot, v) => tot + v.controlScores.reduce((t, cs) => cs.controlCategory === "Apps" || cs.controlCategory === "Data" ? t + cs.score : t, 0), 0);
  var maxScoreCol = secureScoreControlProfiles.reduce((tot, v) => (v.controlCategory === "Apps" || v.controlCategory === "Data") && !v.id.startsWith('MDA_') ? tot + v.maxScore : tot, 0);
  scores.collaboration = maxScoreCol ? Math.round(scoreCol * 100 / maxScoreCol) : 0;

  scores.global = Math.round((scores.identity + scores.device + scores.collaboration) / 3);

  return scores;

}


export { getSecureScore };

