import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import TableFonctionnalitiesSkeleton from "../../../components/LoadingComponent/TableFonctionnalities";
import TabsUnderline from "../../../components/Tabs/TabsUnderline";
import SectionTitle from "../../../components/Text/SectionTitle";

// import components
import Layout from "../../../layout/Layout";
import { SetTrakingHubspot } from "../../../utils/functions/trackingHubspot";
import { getRecommandation } from "../../../utils/requests/licenses";
import RecomendedFonctionnalities from "../../../utils/tables/RecomendedFonctionnalities";
import Licences from "./Licences";
import Security from "./Security";
// import Uses from "./Uses";

const tabs = [
    {
        id: "security",
        name: "Security",
        component: <Security />
    },
    {
        id: "licences",
        name: "Licences",
        component: <Licences />
    }
]


function CSP({ t }) {
    const [loading, setLoading] = useState(0);
    const [currentTab, setTab] = useState("security");
    const [recommandations, setRecommandations] = useState([]);

    useEffect(() => {
        SetTrakingHubspot("csp " + currentTab)
        getRecommandation().then(reco => { setRecommandations(reco); setLoading(1) })
    }, [currentTab])

    return (
        <Layout title="CSP" type="CSP">
            <div className="hidden xl:block">
                <div className="mx-5">
                    <SectionTitle>{t("Security")}</SectionTitle>
                    <Security />
                    {loading ? <RecomendedFonctionnalities recommandations={recommandations} /> : <TableFonctionnalitiesSkeleton length={3} />}
                </div>
                <Licences />
            </div>
            <div className="block xl:hidden">
                <TabsUnderline tabs={tabs} selectedTab={currentTab} setSelectedTab={setTab} />
                {tabs.find(t => t.id === currentTab) ? tabs.find(t => t.id === currentTab).component : null}
                <div className="mx-5">{currentTab === "security" ? (loading ? <RecomendedFonctionnalities recommandations={recommandations} /> : <TableFonctionnalitiesSkeleton length={3} />) : null}</div>
            </div>
        </Layout>
    );
}

export default withTranslation()(CSP);
