import { Dialog } from "@headlessui/react";
import { LockOpenIcon, PlusIcon, ArrowPathIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Button from "../../components/Buttons/Button";
import UserCard from "../../components/Card/UserCard";
import Input from "../../components/Inputs/Input";
import Select from "../../components/Inputs/Select";
import SectionTitle from "../../components/Text/SectionTitle";
import { activateAccountFromTenant, getAllAccountFromHubspot, postAccountHS } from "../../utils/requests/account";
import { getDomaine } from "../../utils/requests/user";
import FormCreateUser from "./FormCreateUser";

function ModalHandleCreateUsers({ t, openForm, setOpenForm, reset, contacts }) {
    const [hubspotMetier, setHubspotMetier] = useState([]);
    const [hubspotNiveau, setHubspotNiveau] = useState([]);
    const [hubspotContacts, setHubspotContacts] = useState([]);
    const [hubspotContactsFiltered, setHubspotContactsFiltered] = useState([]);
    const [emailSearch, setEmailSearch] = useState("");
    const [emailSearched, setEmailSearched] = useState(false);
    const [domains, setDomains] = useState([]);
    const [domain, setDomain] = useState([]);

    useEffect(() => {
        if (openForm && !(hubspotContacts && hubspotContacts.length > 0)) {
            getAllAccountFromHubspot().then(allHSUsers => {
                setHubspotContacts(allHSUsers.contacts);
                setHubspotMetier(allHSUsers.metier);
                setHubspotNiveau(allHSUsers.niveau);
            })

            getDomaine().then(dom => {
                var l = [];
                if (dom.value) {
                    dom.value.forEach(d => {
                        var obj = { value: d.id, label: d.id }
                        if (d.isDefault) {
                            l.splice(0, 0, obj)
                        } else {
                            l.push(obj);
                        }
                    });
                }
                setDomains(l);
            })
        }
    }, [openForm, hubspotContacts])

    function handleSearchEmail() {
        var em = domains && domains.length ? emailSearch + "@" + domain : emailSearch;
        setEmailSearched(true);
        var list = [];
        hubspotContacts.forEach(hsc => {
            if (hsc.identities) {
                hsc.identities.forEach(is => {
                    if (is.identity && is.identity.find(i => i.type === "EMAIL" && i.value === em)) {
                        if (!list.find(l => l === hsc)) {
                            list.push(hsc);
                        }
                    }
                })
            }
        })
        setHubspotContactsFiltered(list);
    }

    function resetForm() {
        setOpenForm(false);
        setEmailSearched(false);
        setEmailSearch("");
        setHubspotContacts([]);
        setHubspotContactsFiltered([]);
        reset();
    }

    return <Dialog
        open={openForm}
        onClose={() => setOpenForm(false)}
        className="relative z-50"
    >
        <div className="fixed inset-0 flex items-center justify-center p-4 bg-gray-500/50">
            <Dialog.Panel className="w-full max-w-sm bg-white border rounded-xl py-5">
                <Dialog.Title className="border-b mb-5 px-5">
                    <SectionTitle>
                        {t("Add a new user")}
                    </SectionTitle>
                </Dialog.Title>
                <Dialog.Description as='div' className="px-5">
                    <div>
                        <div>{t("Email")}</div>
                        <div className="flex">
                            <Input id="email" disabled={emailSearched} onChange={(e) => { setEmailSearch(e.target.value); }} />{domains && domains.length > 0 ? <><span className="mt-1">@</span><Select id="domain" disabled={emailSearched} className="h-9 block w-full sm:text-sm border border-gray-100 py-2 pl-4 pr-2 mr-1 focus:outline-none focus:ring-0 focus:border-primary" values={domains} defaultValue={domains[0]} onChange={(v) => {
                               setDomain(v.target.value);
                            }} /></> : null}
                            {emailSearched ? <Button className="bg-blue-500 text-white px-2" onClick={() => { setEmailSearched(false) }}><ArrowPathIcon className="h-5 w-5" /></Button> : <Button className="bg-blue-500 text-white" onClick={() => { handleSearchEmail() }}><MagnifyingGlassIcon className="h-5 w-5" /></Button>}
                        </div>
                    </div>

                    {emailSearched && hubspotContactsFiltered && hubspotContactsFiltered.length > 0 ? <div className="mt-2">{hubspotContactsFiltered.map(hscf => {
                        var firstname = "";
                        var lastname = "";
                        var email = "";
                        if (!hscf) {
                            return null;
                        } else {
                            if (hscf.identities) {
                                hscf.identities.forEach(is => {
                                    if (is.identity && is.identity.find(i => i.type === "EMAIL")) {
                                        email = is.identity.find(i => i.type === "EMAIL").value;
                                    }
                                })
                            }
                            if (hscf.properties) {
                                if (hscf.properties.find(i => i.name === "firstname")) {
                                    firstname = hscf.properties.find(i => i.name === "firstname").value;
                                }
                                if (hscf.properties.find(i => i.name === "lastname")) {
                                    lastname = hscf.properties.find(i => i.name === "lastname").value;
                                }
                            }
                        }
                        return <UserCard
                            active={true}
                            displayName={<div className="flex">{firstname + " " + lastname}</div>}
                            subtitle={email}
                            buttons={[
                                <div className="w-0 flex-1 flex">
                                    {contacts.find(c => c.mail === email) ? (!contacts.find(c => c.mail === email).activate ? <div
                                        className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                        onClick={() => {
                                            activateAccountFromTenant(email).then((res) => {
                                                if (res) {
                                                    resetForm();
                                                }
                                            });
                                        }}
                                    >
                                        <LockOpenIcon
                                            className="w-5 h-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <span className="ml-3 cursor-pointer">
                                            {t("Activate")}
                                        </span>
                                    </div> : <div className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 bg-gray-100">
                                        {t("Contact already in the list")}
                                    </div>) : <div
                                        className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                        onClick={() => {
                                            postAccountHS(email).then(res => {
                                                if (res.ok) {
                                                    resetForm();
                                                }
                                            })
                                        }}
                                    >
                                        <PlusIcon
                                            className="w-5 h-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <span className="ml-3 cursor-pointer">
                                            {t("Add")}
                                        </span>
                                    </div>}
                                </div>,
                            ]}
                        />
                    })}</div> : (emailSearched ? <FormCreateUser emailSearch={emailSearch} resetForm={resetForm} hubspotMetier={hubspotMetier} hubspotNiveau={hubspotNiveau} /> : null)}
                </Dialog.Description>
            </Dialog.Panel>
        </div>
    </Dialog>
}

export default withTranslation()(ModalHandleCreateUsers)