function ListUserCardSkeleton({ length }) {
    return <div className="mt-3">
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-3 mx-5">
            {Array.apply(0, Array(length)).map(function (_, b) { return b + 1; }).map(l => <li className="mb-2 animate-pulse"><div className="col-span-1 bg-white rounded-lg divide-y divide-gray-200 border">
                <div className="w-full flex items-center justify-between p-6 space-x-6">
                    <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                            <h3 className="text-gray-900 text-sm font-medium truncate">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                            </h3>
                        </div>
                        <p className="mt-1 text-gray-500 text-sm truncate"><div className="h-2 bg-gray-100 rounded-full dark:bg-gray-700 w-16"></div></p>
                    </div>
                    <div className="p-2 rounded-xl col-span-1 h-10 w-10 min-w-10  bg-gray-300 text-gray-400" />
                </div>
            </div></li>)}</ul></div>
}

export default ListUserCardSkeleton;