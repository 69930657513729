import { Dialog } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
import Button from "../../components/Buttons/Button";
import Input from "../../components/Inputs/Input";
import SectionTitle from "../../components/Text/SectionTitle";
import { createTicket } from "../../utils/requests/tickets";
import { toast } from "react-toastify";

function FormIncreaseTMA({ t, tmaType, openForm, setOpenForm }) {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    function onSubmit(data, reset) {
        var tickets = [{
            type: "TMA",
            id: tmaType,
            amount: Number(data.tma)
        }];

        toast.promise(() => createTicket({ tickets: tickets }).then(res => {
            if (res.ok) {
                reset();
                setOpenForm(false);
            } else {
                throw new Error();
            }
        }), {
            pending: t("Creating ticket"),
            success: t("Ticket created ! Your Exaduo contact will contact you concerning this subject."),
            error: t("An error occured. Please retry later.")
        })
    }

    return <Dialog
        open={openForm}
        onClose={() => setOpenForm(false)}
        className="relative z-50"
    >
        <div className="fixed inset-0 flex items-center justify-center p-4 bg-gray-500/50">
            <Dialog.Panel className="w-full max-w-sm bg-white border rounded-xl py-5">
                <Dialog.Title className="border-b mb-5 px-5">
                    <SectionTitle>
                        {t("Increase your {tmaType}", { tmaType: tmaType })}
                    </SectionTitle>
                </Dialog.Title>
                <Dialog.Description as='div' className="px-5">
                    <form
                        className="h-full flex flex-col"
                        onSubmit={handleSubmit((data) => onSubmit(data, reset), reset)}
                    >
                        <div className="px-4 pb-2">
                            <div>
                                <label
                                    htmlFor={"tma"}
                                    className={errors.tma ? "block text-sm font-medium text-red-800 sm:mt-px sm:pt-2" : "block text-sm font-medium text-gray-800 sm:mt-px sm:pt-2"}

                                >
                                    {t("How much do you want to increase your TMA ?")}
                                </label>
                            </div>
                            <div className="flex my-2">
                                <div className="mr-2"><Input id="tma" type="number" register={register} parameters={{ required: "true" }} /></div>
                                {t("days")}</div>
                        </div>
                        <Button type="submit" className=" py-2 px-4 border border-gray-500 font-medium text-white bg-blue-500">
                            {t("Submit")}
                        </Button>
                    </form>
                </Dialog.Description>
            </Dialog.Panel>
        </div>
    </Dialog >
}

export default withTranslation()(FormIncreaseTMA);