import React from "react";
import { withTranslation } from "react-i18next";

class Select extends React.Component {
  render() {
    var { id, values, register, parameters, t, ...props } = this.props;
    return register ? (
      <select
        name={id}
        id={"input-" + id}
        type="text"
        className="block w-full sm:text-sm border border-gray-300 px-2 h-8 focus:outline-none focus:ring-0 focus:border-primary"
        defaultValue=""
        {...register(id, parameters)}
        {...props}
      >
        <option className="text-gray-300" value="" disabled selected>
          {t("Please select an option")}
        </option>
        {values && values.length
          ? values.map((n) => (
              <option key={n.label} value={n.label}>
                {n.label}
              </option>
            ))
          : null}
      </select>
    ) : (
      <select
        name={id}
        id={"input-" + id}
        type="text"
        className="block w-full sm:text-sm border border-gray-300 py-4 pl-4 pr-10 focus:outline-none focus:ring-0 focus:border-primary"
        defaultValue=""
        {...props}
      >
        <option className="text-gray-300" value="" disabled selected>
          {t("Please select an option")}
        </option>
        {values && values.length
          ? values.map((n) => (
              <option key={n.label} value={n.label}>
                {n.label}
              </option>
            ))
          : null}
      </select>
    );
  }
}

export default withTranslation()(Select);
