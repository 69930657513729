import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ListCardSkeleton from "../components/LoadingComponent/List";
import Table from "../components/Tables/Table";

import Layout from "../layout/Layout";
import { getFactures } from "../utils/requests/factures";
import columnsBills from "../utils/tables/columnsBills";


function Bills({ t }) {
  const [loading, setLoading] = useState(0);
  const [bills, setBills] = useState([]);

  useEffect(() => {
    setLoading(0);
    getFactures().then(factures => { 
      setBills(factures); 
      setLoading(1); 
    });
  }, [])

  return (
    <Layout title="Bills">
      {loading === 1 ? (bills && bills.length > 0 ? <Table key="bills" data={bills} columns={() => columnsBills(t)} /> :
        <div className="border-dashed border rounded-lg p-3 m-5 text-center text-sm text-gray-500 italic">
          {t("No bills available")}
        </div>) : <div className="px-2"><ListCardSkeleton length={5} /></div>}
    </Layout>
  );
}

export default withTranslation()(Bills);
