import { ChevronRightIcon } from "@heroicons/react/24/outline";

function ListWithIconsAndDescription({ data }) {
    // return <div key={data.id} className="border border-dashed rounded-xl cursor-pointer" onClick={() => { window.open(data.url, '_blank'); }}>
    //     <div className="border-b border-dashed text-black h-36 rounded-t-xl relative" style={{ backgroundImage: "url(" + data.featuredImage + ")" }}>
    //         <div className="backdrop-blur-sm bg-white/10 h-36 rounded-t-xl"></div>
    //         <div className="font-semibold text-xl text-gray-800 pl-6 pb-2 absolute bottom-0 left-0">{data.htmlTitle}</div>
    //     </div>
    //     <div className="px-4 py-4 flex items-center sm:px-6">
    //         <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
    //             <div className="flex items-center text-sm text-gray-500">
    //                 <p dangerouslySetInnerHTML={{ __html: data.postSummary }} />
    //             </div>
    //         </div>
    //         <div className="ml-5 flex-shrink-0">
    //             <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    //         </div>
    //     </div>
    // </div >

    return <div key={data.id} className="border border-dashed rounded-xl cursor-pointer flex p-2" onClick={() => { window.open(data.url, '_blank'); }}>
        <div className="flex items-center w-96">
            <img className="rounded-xl w-full" src={data.featuredImage} alt={data.htmlTitle} />

        </div>
        <div className="pl-4 flex items-center">
            <div>
                <div className="font-semibold text-lg">{data.htmlTitle}</div>
                <p className="text-xs text-gray-500" dangerouslySetInnerHTML={{ __html: data.postSummary }} />
            </div>
            <div className="ml-5 flex-shrink-0">
                <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
        </div>

    </div>
}

export default ListWithIconsAndDescription;