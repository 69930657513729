import React, {useEffect, useState, Fragment} from "react";
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export default function SelectMenu({options, currentTenant, onClick})
{
    const [selected, setSelected] = useState(null)
    useEffect(()=>{
        if(options && options.length)
        {
            setSelected(options.find(opt => opt.idTenant === currentTenant))
        }
    }, [options, currentTenant])

    return (
    <Listbox value={selected} onChange={(value) =>{
      setSelected(value);
      onClick(value.idTenant);
    }}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Change tenant</Listbox.Label>
          <div className="relative">
            <div className="inline-flex rounded-md border border-gray-300 ">
              <div className="relative z-0 inline-flex rounded-md">
                <div className="relative inline-flex items-center bg-white py-2 pl-3 pr-4 rounded-l-md text-gray-500">
                  <p className="ml-2.5 text-sm font-medium">{selected && selected.title}</p>
                </div>
                <Listbox.Button className="relative inline-flex items-center bg-white p-2 rounded-l-none rounded-r-md text-sm font-medium">
                  <span className="sr-only">Change tenant</span>
                  <ChevronDownIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-blue-900' : 'text-gray-900',
                        'cursor-default select-none relative p-4 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={selected ? 'font-semibold' : 'font-normal'}>{option.title}</p>
                        </div>
                        <p className={classNames(active ? 'text-blue-200' : 'text-gray-500', 'mt-2')}>
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}