const configuration = {
  auth: {
    authority:"https://login.microsoftonline.com/organizations",
    clientId: process.env.REACT_APP_CLIENTID,
    redirectUri: process.env.REACT_APP_REDIRECTURI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
};

const loginRequest = {
  scopes: ["User.Read", "SecurityEvents.Read.All", "Organization.Read.All", "User.ReadBasic.All"],
  prompt: 'select_account'
};

const protectedResources = {
  api: {
    endpoint: window.location.origin + "/api",
    scopes: ["api://" + process.env.REACT_APP_CLIENTID + "/access_as_user"], // e.g. api://xxxxxx/access_as_user
  },
};

export { configuration, loginRequest, protectedResources };
