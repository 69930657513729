import { getAccessToken } from "../../auth/verifyAuth";

async function createTicket(tickets) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/tickets", {
        method: "POST",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(tickets)
    });
}


export { createTicket };
