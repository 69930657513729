import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Card from "../components/Card/Card";
import ConsommationAzureBar from "../utils/chart/ConsommationAzureBar";
import { getSecureScore } from "../utils/requests/secureScore";
import TableFonctionnalities from "../utils/tables/TableFonctionnalities";
import { getAzureConsommation } from "../utils/requests/azure";
import Layout from "../layout/Layout";
import { InformationCircleIcon, UsersIcon } from "@heroicons/react/24/outline";
import { ClockIcon, CloudIcon, DocumentTextIcon, ShieldCheckIcon } from "@heroicons/react/24/solid";
import ReactTooltip from "react-tooltip";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { getAllLicences } from "../utils/requests/licenses";
import { SetTrakingHubspot } from "../utils/functions/trackingHubspot";
import { getFactures } from "../utils/requests/factures";
import { getTMA } from "../utils/requests/tma";
import { getProjects } from "../utils/requests/projects";
import ProgressBar from "@ramonak/react-progress-bar";
import StatisticsCard from "../components/Card/StatisticCard";
import { getAllTenants, getTenant } from "../utils/requests/tenant";
import StatisticsCardSkeleton from "../components/LoadingComponent/StatisticsCard";
import Graph from "../components/LoadingComponent/Graph";
import ListCardSkeleton from "../components/LoadingComponent/List";
import TableFonctionnalitiesSkeleton from "../components/LoadingComponent/TableFonctionnalities";

function Home({ t }) {
  const [loading, setLoading] = useState([0]);
  const [client, setClient] = useState(null);
  const [secureScore, setSecureScore] = useState(null);
  const [consommation, setConsommation] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [bills, setBills] = useState([]);
  const [TMA, setTMA] = useState([]);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    SetTrakingHubspot();
    setLoading([0, 0, 0, 0, 0, 0, 0])
    getAllTenants().then((tenants) => {
      if (tenants && tenants.length) {
        setLoading(l => { l[0] = 1; return l });
        setClient(tenants[0]);
        setSecureScore(tenants[0].secureScore)
      } else {
        getTenant().then((tenant) => {
          setLoading(l => { l[0] = 1; return l });
          setClient(tenant);
          setSecureScore(tenant.secureScore)
        });
      }
    });
    getSecureScore().then((secureSc) => {
      setLoading(l => { l[1] = 1; return l });
      if (secureSc && secureSc.length) {
        setSecureScore(secureSc);
      }
    });
    getAzureConsommation().then(azConsom => {
      setLoading(l => { l[2] = 1; return l });
      setConsommation(azConsom);
    })
    getAllLicences().then(licences => {
      setLoading(l => { l[3] = 1; return l });
      if (licences && licences.length) {
        var listSub = [];
        licences.forEach(licence => {
          if (licence.products && licence.products.length > 0) {
            licence.products.forEach(p => {
              if (p && p != null && !listSub.find(s => s.id === p.id)) {
                listSub.push(p);
              }
            });
          }
        })
        setSubscriptions(listSub);
      }
    })
    getFactures().then(factures => {
      setLoading(l => { l[4] = 1; return l });
      setBills(factures)
    });
    getTMA().then(t => {
      setLoading(l => { l[5] = 1; return l });
      var timeLeft = 0;
      if (t && t.length > 0) {
        timeLeft = t.reduce((tot, val) => { return tot + val.timeLeft }, 0);
      }
      setTMA(timeLeft);
    });
    getProjects().then(proj => {
      setLoading(l => { l[6] = 1; return l });
      setProjects(proj)
    });
  }, []);

  let navigate = useNavigate();

  return (
    <Layout title="Dashboard">
      <div className="p-5">
        <div className="grid xl:grid-cols-5 lg:grid-cols-2 gap-4 items-stretch">
          {client && loading[0] === 1 ? <div className="col-span-1">
            <StatisticsCard Icon={UsersIcon} color="#ffdb0a" amount={client.estimatedUserQualify} description={t("estimated user qualify")} />
          </div> : <div className="col-span-1">
            <StatisticsCardSkeleton Icon={UsersIcon} description={t("estimated user qualify")} />
          </div>}
          {client && loading[0] === 1  ? <div className="col-span-1">
            <StatisticsCard Icon={DocumentTextIcon} color="#0aa9ff" amount={client.estimatedETP ? client.estimatedETP : "N/A"} description={t("estimated etp")} />
          </div> : <div className="col-span-1">
            <StatisticsCardSkeleton Icon={DocumentTextIcon} description={t("estimated etp")} />
          </div>}
          {loading[5] === 1 ? <div className="col-span-1">
            <StatisticsCard Icon={ClockIcon} color={TMA > 0 ? "#349906" : "#ff0a58"} amount={TMA ? (Math.round(TMA) + "h") : "0h"} description={t("TMA left")} />
          </div> : <StatisticsCardSkeleton Icon={ClockIcon} description={t("TMA left")} />}
          {secureScore && <div className="col-span-1">
            <StatisticsCard Icon={ShieldCheckIcon} color="#600AFF" amount={secureScore ? (secureScore.global + "%") : "N/A"} description={t("Secure Score")} />
          </div>}
          {loading[2] === 1 ? <div className="col-span-1">
            <StatisticsCard Icon={CloudIcon} color="#0a7eff" amount={consommation && consommation.length ? Math.round(parseInt(consommation[consommation.length - 1].cost)) + "€" : 0 + "€"} description={t("Last azure consumption")} />
          </div> : <div className="col-span-1">
            <StatisticsCardSkeleton Icon={CloudIcon} description={t("Last azure consumption")} />
          </div>}
        </div>
      </div>
      <div className="p-5">
        <div className="xl:grid xl:grid-cols-3 gap-4">
          <div className="col-span-2 mb-5 md:mb-0">
            <Card title={
              <div className="flex">
                {t("My Azure Consumption")}
                <div data-tip data-for="azureConsInfo">
                  <InformationCircleIcon className="ml-1 mt-2 h-4 text-gray-500" />
                </div>
                <ReactTooltip id="azureConsInfo" type="light">
                  <span>{t("Description azure consumption")}</span>
                </ReactTooltip>
              </div>
            }
              actions={[<span className="inline-flex items-center text-sm font-medium space-x-1 cursor-pointer" style={{ color: "#0a58ff" }} onClick={() => navigate("/azure")}>
                <span>{t("Consult consumption")}</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </span>]}
            >
              {loading[2] === 0 ? <Graph /> : (consommation ? <>
                <ConsommationAzureBar azureConsommation={consommation} />
              </> : <div className="h-48 md:w-40 md:h-64 md:w-full">
                <div className="h-16 w-16 mx-auto my-12">
                  {t("No Azure Consumption")}
                </div>
              </div>)}
            </Card>
          </div>
          <div className="col-span-1 mt-4 xl:mt-0">
            <Card noBorder={true} title={t("Projects")} actions={[<span className="inline-flex items-center text-sm font-medium space-x-1 cursor-pointer" style={{ color: "#0a58ff" }} onClick={() => navigate("/tma-and-projects")}>
              <span>{t("Consult all projects")}</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </span>]}>
              {loading[6] === 0 ? <ListCardSkeleton length={2} /> :
                (projects && projects.length > 0 ?
                  projects.map((p, k) => <div className="mb-2"><Card key={k}>
                    <div className="grid grid-cols-3">
                      <div className="col-span-2">
                        <div className="font-normal">{p.name}</div>
                        <div className="text-gray-400">{p.type}</div>
                      </div>
                      <div>
                        <ProgressBar bgColor="#349906" completed={p.totaltask > 0 ? Math.round(p.donetask * 100 / p.totaltask) : 0} />
                      </div>
                    </div>
                  </Card></div>)
                  : <div className="border-dashed text-center border rounded-lg p-3 mt-2 text-sm text-gray-500 italic">{t("No projects available")}</div>)}
            </Card>
            <div className="mt-4">
              <Card noBorder={true} title={t("Last Bills")} actions={[<span className="inline-flex items-center text-sm font-medium space-x-1 cursor-pointer" style={{ color: "#0a58ff" }} onClick={() => navigate("/bills")}>
                <span>{t("Consult all bills")}</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </span>]}>
                {loading[4] === 0 ? <ListCardSkeleton length={2} /> : (bills && bills.length > 0 ? bills.slice(0, 3).map((b, k) => <div className="mb-2"><Card key={k}><div className="flex cursor-pointer" onClick={() => window.open(b.link, "_blank")}>
                  <DocumentTextIcon className="p-2 rounded-lg col-span-1 h-12 w-12 min-w-12" style={{ backgroundColor: "#600AFF20", color: "#600AFF" }} />
                  <div className="ml-4 col-span-11 md:col-span-5 xl:col-span-11">
                    <div className="font-normal">{b.name}</div>
                    <div className="text-gray-400">{b.type}</div>
                  </div>
                </div></Card></div>) : <div className="border-dashed border rounded-lg p-3 mt-2 text-sm text-gray-500 italic text-center">{t("No bills available")}</div>)}
              </Card>
            </div>
          </div>
        </div>
      </div>
      <div className=" p-5 mt-5">
        <Card noBorder title={t("My application")}>
          <div className="mt-4">
            {loading[3] === 1 ? <TableFonctionnalities subscriptions={subscriptions} /> : <TableFonctionnalitiesSkeleton length={4} />}
          </div>
        </Card>
      </div>
    </Layout >
  );
}

export default withTranslation()(Home);
