import React, { useEffect, useState } from 'react'
import { getUser } from "../../auth/verifyAuth";
import MobileMenu from "./Mobile/Menu";
import Aside from "./Aside";
import HeaderMobile from './Mobile/Header';
import routes from "../../utils/router/routes";
import { Outlet } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Header from "./Header";
import { getIfRegistered } from '../../utils/requests/user';
import { getAllTenants, getTenant } from "../../utils/requests/tenant";
import { getUserPhoto } from "../../utils/requests/user";

export const IsAdminContext = React.createContext();

function Global({ t }) {
  const [photo, setPhoto] = useState({});
  const [tenants, setTenants] = useState([]);
  const [currentTenant, setCurrentTenant] = useState({})
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [owner, setOwner] = useState({});

  const user = getUser();

  useEffect(() => {
    getIfRegistered().then(res => {
      if (res) {
        if (res.isAdmin) {
          setIsAdmin(true);
        }
        setIsRegistered(true);
      }
    });
    getUserPhoto().then((photoAPI) => {
      setPhoto(photoAPI);
    });
    getAllTenants().then(tenantsAPI => {
      setTenants(tenantsAPI);
    })
    getTenant().then(tenantAPI => {
      setCurrentTenant(tenantAPI.idTenant)
      setOwner(tenantAPI ? tenantAPI.owner : null);
    })
  }, [])

  return (
    <div>
      <div className="h-screen w-screen overflow-x-hidden overflow-y-auto">
        <div className="block md:hidden">
          <MobileMenu owner={owner} mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} navigation={routes.filter((r) => r.isMenuIcon)} user={user} isMultiTenant={tenants && tenants.length === 0} />
        </div>
        <div className="hidden md:block">
          <Aside owner={owner} navigation={routes.filter((r) => r.isMenuIcon)} user={user} isMultiTenant={tenants && tenants.length === 0} />
        </div>
        <div className="md:pl-64 flex flex-col">
          {tenants && tenants.length > 1 ? <Header photo={photo} tenants={tenants} currentTenant={currentTenant} /> : null}
          <HeaderMobile setMobileMenuOpen={setMobileMenuOpen}/>
          <main className="flex-1">
            <section className="w-full h-full">
              <div className="uppercase py-2 text-center" style={{ backgroundColor: "#ffb10a", color: "#fff", fontSize: "16px", fontWeight: "700" }}>{t("Beta version : data and visual may not be exact")}</div>
              <IsAdminContext.Provider value={isAdmin}>
                {isRegistered ? <Outlet /> : <>{t("You are not registered on the app. Please ask your technical admin or your exaduo contact to add you.")}</>}
              </IsAdminContext.Provider>
            </section>
          </main>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Global);
