import { useForm } from "react-hook-form";
import { withTranslation } from "react-i18next";
import Button from "../../components/Buttons/Button";
import Structure from "../../components/Forms/Structure";
import Input from "../../components/Inputs/Input";
import Select from "../../components/Inputs/Select";
import { postAccount } from "../../utils/requests/account";

function FormCreateUser({ t, emailSearch, resetForm, hubspotMetier, hubspotNiveau}) {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    function onSubmit(data, reset) {
        var contact = data;
        contact.email = emailSearch;
        postAccount(contact).then(res => {
            if (res.ok) {
                reset();
                resetForm();
            }
        })
    }

    return <div>
        <form
            className="h-full flex flex-col"
            onSubmit={handleSubmit((data) => onSubmit(data, reset), reset)}
        >
            <Structure
                id="firstname"
                title={t("First name")}
                error={errors.firstname}
                required
                component={() => (
                    <Input id="firstname" register={register} parameters={{ required: "true" }} />
                )}
            />
            <Structure
                id="lastname"
                title={t("Last name")}
                error={errors.lastname}
                required
                component={() => (
                    <Input id="lastname" register={register} parameters={{ required: "true" }} />
                )}
            />
            <Structure
                id="jobtitle"
                title={t("jobtitle")}
                error={errors.jobtitle}
                required
                component={() => (
                    <Input id="jobtitle" register={register} parameters={{ required: "true" }} />
                )}
            />
            {hubspotMetier && <Structure
                id="metier"
                title={t("metier")}
                error={errors.metier}
                required
                component={() => (
                    <Select id="metier" values={hubspotMetier} register={register} parameters={{ required: true }} />
                )}
            />}
            {hubspotNiveau && <Structure
                id="niveau"
                title={t("niveau")}
                error={errors.niveau}
                required
                component={() => {
                    return <Select id="niveau" values={hubspotNiveau} register={register} parameters={{ required: true }} />
                }}
            />}
            <Structure
                id="phone"
                title={t("phone")}
                error={errors.phone}
                required
                component={() => (
                    <Input id="phone" register={register} parameters={{ required: "true" }} />
                )}
            />
            <Structure
                id="mobilephone"
                title={t("mobilephone")}
                error={errors.mobilephone}
                required
                component={() => (
                    <Input id="mobilephone" register={register} parameters={{ required: "true" }} />
                )}
            />
            <Button type="submit" className=" py-2 px-4 rounded-xl font-medium text-white bg-blue-500">
                {t("Submit")}
            </Button>
        </form>
    </div>
}

export default withTranslation()(FormCreateUser);