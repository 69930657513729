export default function columnsBills(t) {
    var col = [
        {
            key: "name",
            visible: true,
            label: t("Name"),
            exportableCSV: true,
            id: "name",
            width: 300,
            format: (row) => (
                <span className="font-bold cursor-pointer" onClick={() => { window.open(row.link, "_blank") }}>
                    {row.name}
                </span>

            ),
        },
        {
            key: "type",
            visible: true,
            label: t("Type"),
            exportableCSV: true,
            filter: { type: "select" },
            id: "type",
            width: 300,
            format: (row) => (
                <span>
                    {row.type}
                </span>

            ),
        },
        {
            key: "date",
            visible: true,
            label: t("endDate"),
            exportableCSV: true,
            filter: { type: "date" },
            id: "date",
            width: 300,
            format: (row) => {
                var date = new Date(row.date)
                return <span>
                    {(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())}
                </span>
            }
        },
        {
            key: "link",
            visible: false,
            label: t("Link"),
            exportableCSV: true,
            id: "link",
            width: 300,
            format: (row) => (
                <span>
                    {row.link}
                </span>

            )
        }
    ];

    return col
}