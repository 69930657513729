import { withTranslation } from "react-i18next"
import { DevicePhoneMobileIcon, UserIcon, UsersIcon } from "@heroicons/react/24/outline";
import SectionTitle from "../../components/Text/SectionTitle";
import ModalRecommandations from "../../pages/uses/csp/ModalRecommandations";
import { useEffect, useState } from "react";

function RecomendedFunctionnalities({ recommandations, t, max }) {
  const [openForm, setOpenForm] = useState(false);
  const [selectedLicence, setSelectedLicence] = useState(false);
  const [missing, setMissing] = useState([]);

  useEffect(() => {
    let m = null;
    if (recommandations) {
      m = recommandations.filter(r => r.recommandation);

      if (!m || m.length === 0) {
        setMissing(null);
      } else {
        if (max) {
          m = m.slice(0, max)
        }
        setMissing(m)
      }
    }
  }, [recommandations, max])


  return missing && missing.length ?
    <div className="pb-5">
      <ModalRecommandations licence={selectedLicence} openForm={openForm} setOpenForm={setOpenForm} />
      <SectionTitle>{t("Recomended Products")}</SectionTitle>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-2 mt-2">
        {missing.sort((a, b) => a.recommandation_order - b.recommandation_order).slice(0, 3).map((d, k) => {
          var className = "p-4 rounded-lg border border-dashed flex bg-white";
          return <div key={k} className={className} onClick={() => { setSelectedLicence(d); setOpenForm(true); }}>
            {d.tag && d.tag.includes("device") ?
              <DevicePhoneMobileIcon className="p-2 rounded-lg col-span-1 h-12 w-12 min-w-12" style={{ backgroundColor: "#0a7eff20", color: "#0a7eff" }} />
              : (d.tag && d.tag.includes("identity") ? <UserIcon className="p-2 rounded-lg col-span-1 h-12 w-12 min-w-12" style={{ backgroundColor: "#600AFF20", color: "#600AFF" }} />
                : <UsersIcon className="p-2 rounded-lg col-span-1 h-12 w-12 min-w-12" style={{ backgroundColor: "#34990620", color: "#349906" }} />)
            }
            <div className="ml-4 col-span-11 md:col-span-5 xl:col-span-11">
              <div style={{ verticalAlign: "middle" }} className={d.link ? "cursor-pointer font-medium " : "font-medium text-gray-400"}>{d.nom}</div>
              <div className="flex gap-1">{d.tag ? d.tag.split(";").map((t, k) => <div key={k} className="bg-gray-200 rounded-full px-2 text-sm text-gray-600">{t}</div>) : null}</div>
            </div>
          </div>
        })}</div></div> : null
}

export default withTranslation()(RecomendedFunctionnalities)