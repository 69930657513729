import { getUser } from "../../auth/verifyAuth"

// <!-- Start of HubSpot Embed Code -->
// <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8791248.js"></script>
// <!-- End of HubSpot Embed Code -->

async function SetTrakingHubspot( page ) {
    window.document.title = page ? "Exaduo - Espace client (" + page + ")" : "Exaduo - Espace client";
    var _hsq;
    var scripts = Array.from(document.getElementsByTagName("script")).filter(script =>
        script.src === "https://js.hs-scripts.com/8526577.js").length
    if (!scripts) {
        const curentUser = getUser();
        // const curentUser = JSON.parse(window.sessionStorage.getItem(Object.keys(window.sessionStorage).filter(key => key.includes("-login.windows.net-")).sort()[0]));
        _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/' + page]);
        _hsq.push(["identify", { email: curentUser.username }]);

        const script = document.createElement('script');
        script.src = 'https://js.hs-scripts.com/8526577.js';
        document.body.appendChild(script);
    } else {
        _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', '/' + page]);
        _hsq.push(['trackPageView']);
    }
}

export { SetTrakingHubspot }