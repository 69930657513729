import { ChevronRightIcon, DocumentTextIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import LoadingSpinner from "../components/Animation/LoadingSpinner";
import Button from "../components/Buttons/Button";
import Card from "../components/Card/Card";
import Layout from "../layout/Layout";
import ConsommationAzureBar from "../utils/chart/ConsommationAzureBar";
import { SetTrakingHubspot } from "../utils/functions/trackingHubspot";
import { getAzureConsommation } from "../utils/requests/azure";
import { getFactures } from "../utils/requests/factures";
import NotFound from "./NotFound";
import { useNavigate } from "react-router-dom";
import { getBlogPostsByType } from "../utils/requests/blogs";
import ListWithIconsAndDescription from "../components/Tables/List/ListWithIconsAndDescription";
import SectionTitle from "../components/Text/SectionTitle";
import Graph from "../components/LoadingComponent/Graph";
import ListCardSkeleton from "../components/LoadingComponent/List";


function AzureConsommation({ t }) {
  const [loading, setLoading] = useState([0, 0, 0]);
  const [bills, setBills] = useState([]);
  const [consommation, setConsommation] = useState([]);
  const [blogs, setBlogs] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    SetTrakingHubspot("azure");
    getAzureConsommation().then(azConsom => {
      setLoading(l => { l[0] = 1; return l; });
      setConsommation(azConsom)
    }).catch(err => {
      setLoading(l => { l[0] = 1; return l; });
    })
    getFactures().then(factures => {
      setLoading(l => { l[1] = 1; return l; });
      if (factures.find(f => f.type === "Consommation Azure")) {
        setBills(factures.filter(f => f.type === "Consommation Azure"))
      }
    })
    getBlogPostsByType("Azure").then(posts => {
      setLoading(l => { l[2] = 1; return l; });
      setBlogs(posts);
    })
  }, [])
  return <Layout title="Azure Consommation" type="azure" actions={[
    <Button className="text-[#0a7eff] p-4" onClick={() => { window.open("https://portal.azure.com/#view/Microsoft_Azure_CostManagement/Menu/~/costanalysis", "_blank") }}>{t("Access to Azure Cost Management")}</Button>,
    <Button className="bg-[#0a7eff] text-white p-4 rounded-xl" onClick={() => { window.open("https://portal.azure.com/#view/Microsoft_Azure_Expert/AdvisorMenuBlade/~/score", "_blank") }}>{t("Access to Azure Advisor")}</Button>]}>
    <div className="xl:grid xl:grid-cols-3 gap-5 mx-5">
      <div className="col-span-2 p-5 border border-dashed rounded-xl">
        {loading[0] === 1 ? (consommation ? <ConsommationAzureBar azureConsommation={consommation} /> : <div>{t("No Consumption found")}</div>) : <Graph />}
      </div>
      <div className="mt-5 xl:mt-0">
        <Card noBorder={true} title={t("Last Bills")} actions={[<span className="inline-flex items-center text-sm font-medium space-x-1 cursor-pointer" style={{ color: "#0a58ff" }} onClick={() => navigate("/bills")}>
          <span>{t("Consult all bills")}</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </span>]}>
          {loading[1] === 0 ? <ListCardSkeleton length={3} /> : (bills && bills.length > 0 ? bills.slice(0, 6).map((b, k) => <div className="mb-2" key={k}><Card ><div className="flex cursor-pointer" onClick={() => window.open(b.link, "_blank")}>
            <DocumentTextIcon className="p-2 rounded-lg col-span-1 h-12 w-12 min-w-12" style={{ backgroundColor: "#0aa9ff20", color: "#0aa9ff" }} />
            <div className="ml-4 col-span-11 md:col-span-5 xl:col-span-11">
              <div className="font-normal">{b.name}</div>
              <div className="text-gray-400">{b.type}</div>
            </div>
          </div></Card></div>) : <div className="border-dashed border rounded-lg p-3 mt-2 text-sm text-gray-500 italic text-center">{t("No bills available")}</div>)}
        </Card>
      </div>
    </div>
    <div className="mx-5 mt-4">
      <SectionTitle>{t("Blogs")}</SectionTitle>
      <div className="grid xl:grid-cols-2 gap-2 mt-2">
        {loading[2] === 0 ? <ListCardSkeleton length={2} /> : (blogs && blogs.length > 0 ? blogs.map((b, k) => <ListWithIconsAndDescription data={b} key={k} />) :
          <div className="border-dashed border rounded-lg p-3 mt-2 text-sm text-gray-500 italic text-center">{t("No blogs available")}</div>)}
      </div>
    </div>
  </Layout>;
}

export default withTranslation()(AzureConsommation);
