import { getAccessToken } from "../../auth/verifyAuth";

async function getAzureConsommation() {
    let oldAzure = localStorage.getItem("azure");
    if (oldAzure) {oldAzure = JSON.parse(oldAzure)}

    if (!oldAzure || !oldAzure.data || ((new Date(oldAzure.time)).getDate() !== (new Date()).getDate())) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/azure/consumption",
        {
            method: "GET",
            headers: {
                "my-Authorization": `Bearer ${accessToken}`
            }
        }
    )
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then(res =>{
            if(res && res.length)
            {
                const resMap = res.map(r =>{
                    var date = new Date(r.date);
                    r.month = date.getMonth() + 1;
                    r.year = date.getFullYear();
                    return r;
                });
                localStorage.setItem("azure", JSON.stringify({"data" : resMap, "time" : new Date()}))
                return resMap
            } else {
                return res;
            }
        })
    } else {
        return oldAzure.data;
    }
}

export { getAzureConsommation };
