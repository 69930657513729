import { Dialog } from "@headlessui/react";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Button from "../../../components/Buttons/Button";
import SectionTitle from "../../../components/Text/SectionTitle";
import { createTicket } from "../../../utils/requests/tickets";

function ModalHandleCreateUsers({ t, openForm, setOpenForm, licence }) {

    function askQuote() {
        var tickets = [{
            type: "NewLicence",
            id: licence.id
        }];

        toast.promise(() => createTicket({ tickets: tickets }).then(res => {
            if (res.ok) {
                setOpenForm(false);
            } else {
                throw new Error();
            }
        }), {
            pending: t("Creating ticket"),
            success: t("Ticket created ! Your Exaduo contact will contact you concerning this subject."),
            error: t("An error occured. Please retry later.")
        })
    }
    return <Dialog
        open={openForm}
        onClose={() => setOpenForm(false)}
        className="relative z-50"
    >
        <div className="fixed inset-0 flex items-center justify-center p-4 bg-gray-500/50">
            <Dialog.Panel className="w-full max-w-md bg-white border rounded-xl py-5">
                <Dialog.Title className="border-b mb-5 px-5">
                    <SectionTitle>
                        {licence.nom}
                    </SectionTitle>
                </Dialog.Title>
                <Dialog.Description as='div' className="px-5">
                    <div className="pb-6">
                        {licence.description}
                        {licence.link ? <Button className="font-medium bg-white rounded-xl text-blue-500 mr-2" onClick={() => { window.open(licence.link, '_blank'); }}>{t("Get more information")}</Button> : null}
                    </div>
                    <Button className=" py-2 px-4 font-medium text-white rounded-xl bg-blue-500 w-full" onClick={() => { askQuote() }}>{t("Ask for a quote")}</Button>
                </Dialog.Description>
            </Dialog.Panel>
        </div>
    </Dialog>
}

export default withTranslation()(ModalHandleCreateUsers)