import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import DTSkeleton from "../components/LoadingComponent/DTSkeleton";
import SectionTitle from "../components/Text/SectionTitle";
import Layout from "../layout/Layout";
import { SetTrakingHubspot } from "../utils/functions/trackingHubspot";
import { getAllTenants, getTenant } from "../utils/requests/tenant";
const usefulLink = require("./usefulLink.json");

function TenantInformation({ t }) {
    const [loading, setLoading] = useState(0)
    const [DT, setDT] = useState([]);
    const [client, setClient] = useState(null);

    useEffect(() => {
        setLoading(0)
        SetTrakingHubspot("informations");
        getAllTenants().then((tenants) => {
            if (tenants && tenants.length) {
                setLoading(1)
                var formattedDT = [];
                formattedDT.push({
                    id: 1,
                    title: "tenants",
                    value: tenants.map((t) => t.idTenant),
                });
                formattedDT.push({
                    id: 2,
                    title: "estimated user qualify",
                    value: tenants[0] ? tenants[0].estimatedUserQualify : "N/A",
                });
                formattedDT.push({
                    id: 3,
                    title: "estimated etp",
                    value: tenants[0] ? tenants[0].estimatedETP : "N/A",
                });
                setDT(formattedDT);
                setClient(tenants[0] ? tenants[0].name : "N/A");
            } else {
                getTenant().then((tenant) => {
                    setLoading(1)
                    var formattedDT = [];
                    formattedDT.push({
                        id: 1,
                        title: "tenants",
                        value: [tenant.idTenant],
                    });
                    formattedDT.push({
                        id: 2,
                        title: "estimated user qualify",
                        subtitle: "UserQualified description",
                        value: tenant ? tenant.estimatedUserQualify : "N/A",
                    });
                    formattedDT.push({
                        id: 3,
                        title: "estimated etp",
                        subtitle: "ETP description",
                        value: tenant ? tenant.estimatedETP : "N/A",
                    });
                    setDT(formattedDT);
                    setClient(tenant ? tenant.name : null);
                });
            }
        });
    }, []);

    return <Layout loading={!loading} title={client}>
        <div className="mx-5 border border-dashed p-4 rounded-xl">
            <SectionTitle>{t("Information")}</SectionTitle>
            {!loading ? <DTSkeleton length={3} /> : <div className="mt-2">{DT.map(d => <div className="flex justify-between py-4 text-sm text-gray-500">
                <div className="text-md text-gray-800 flex">{t(d.title)}{d.subtitle ? <div><div data-tip data-for={d.title}>
                    <InformationCircleIcon className="ml-1 h-4 text-gray-500" />
                </div>
                    <ReactTooltip id={d.title} type="light" className="w-96">
                        <span>{t(d.subtitle)}</span>
                    </ReactTooltip></div> : null}</div>
                <div className="text-md text-gray-500 flex flex-col">
                    {d.value && d.value.length ? d.value.map(v => <div>{v}</div>) : d.value}
                </div>
            </div>)}</div>}
        </div>

        <div className="mt-5 mx-5">
            <SectionTitle>{t("Useful link")}</SectionTitle>
            <p className="mt-2 grid grid-cols-3 gap-2">{usefulLink.map(l => <p key={l.id} className="cursor-pointer text-xs border border-dashed rounded-xl p-3 flex items-center" onClick={() => { window.open(l.link, "_blank") }}>{l.name}</p>)}</p>
        </div>
    </Layout>
}

export default withTranslation()(TenantInformation);