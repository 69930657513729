import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Button from "../Buttons/Button";
import SearchBar from "./ToolBar/SearchBar";
import Select from "./ToolBar/Select";
import ToolBar from "./ToolBar/ToolBar";

function Table({ columns, data, t, actions, noToolbar, noHeader }) {
    const [count, setCount] = useState(30);

    // handle filter
    const [dataFiltered, setFilteredData] = useState([]);
    const [dataSearchBar, setDataSeachBar] = useState([]);
    const [selectBar, setSelectBar] = useState({});

    // handle sort
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState(1);


    useEffect(() => {
        setFilteredData(data);
        setDataSeachBar(data);
        setSelectBar({});
        setCount(30);
    }, [data]);

    function handleSelectFilter() {
        setFilteredData([]);
        var dataFilteredUpdated = dataSearchBar;
        var columnsToFiltered = typeof columns === "function" ? columns(dataFilteredUpdated).filter(c => c.filter) : columns.filter(c => c.filter);

        if (columnsToFiltered && columnsToFiltered.length) {
            dataFilteredUpdated = dataFilteredUpdated.filter(d => {
                var result = true;
                columnsToFiltered.forEach(c => {
                    if (selectBar[c.id] && selectBar[c.id].length && !selectBar[c.id].find(s => s === d[c.key])) {
                        result = false;
                    }
                })
                return result;
            });
        }
        dataFilteredUpdated = handleSortData(dataFilteredUpdated, sortField, sortDirection)
        setFilteredData(dataFilteredUpdated);
    };

    function handleSortData(dataFilteredUpdated, sF, sD) {
        if (dataFilteredUpdated && dataFilteredUpdated.length) {
            dataFilteredUpdated.sort((a, b) => {
                if (!a[sF]) return 1
                if (!b[sF]) return -1
                return a[sF] < b[sF] ? sD : -1 * sD
            })
        }
        return dataFilteredUpdated;
    }

    function handleHeader(column) {
        if (column.underlineColor) {

            return <th key={column.id}
                scope="col"
                className=" text-left text-gray-500 uppercase"
                onClick={() => handleSort(column.id)}
            >
                <span className="inline-flex items-center py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800" style={{ color: "#FFFFFF", backgroundColor: column.underlineColor }}>
                    {typeof column.label === "function" ? column.label() : (column.formatHeader ? column.formatHeader(column.label) : column.label)}
                    {handleSortDirection(column.id)}
                </span>
            </th>
        } else {
            return <th key={column.id}
                scope="col"
                className="px-6 py-3 2xl:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                onClick={() => handleSort(column.id)}
            >
                {typeof column.label === "function" ? column.label() : (column.formatHeader ? column.formatHeader(column.label) : column.label)}
                {handleSortDirection(column.id)}
            </th>
        }
    }

    function handleSortDirection(columnKey) {
        if (columnKey) {
            if (sortField === columnKey && sortDirection === -1) {
                return <span className="text-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-4" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                        <path data-name="layer1"
                            d="M21 22a2.99 2.99 0 0 1 2.121.879l8.89 8.636 8.868-8.636a3 3 0 0 1 4.242 4.242L32.011 40 18.879 27.121A3 3 0 0 1 21 22z"
                            fill="currentColor"></path>
                    </svg>
                </span>
            } else if (sortField === columnKey) {
                return <span className="text-lg">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-4" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                        <path data-name="layer1"
                            d="M43 40a2.992 2.992 0 0 1-2.121-.879l-8.89-8.635-8.868 8.635a3 3 0 0 1-4.242-4.242L31.989 22l13.132 12.879A3 3 0 0 1 43 40z"
                            fill="currentColor"></path>
                    </svg>

                </span>
            }
        }
    }

    function handleSort(columnKey) {
        var sD = sortDirection;
        if (columnKey === sortField) {
            sD *= -1
            setSortDirection(sD);
        }
        setSortField(columnKey);
        setFilteredData(handleSortData(dataFiltered, columnKey, sD));
    }

    return (<>
        {!noToolbar && <ToolBar
            components={
                <>
                    <h3 className="text-sm 2xl:text-base font-semibold p-2 pr-2 2xl:pr-4 truncate">
                        {dataFiltered && dataFiltered.length} {" "} {t("results")}
                    </h3>
                    <span className="mx-3 2xl:mx-5 w-1 border-l" />
                    <SearchBar data={data} setFilteredData={(value) => {
                        setDataSeachBar(value);
                        handleSelectFilter();
                    }} />
                    <span className="mx-3 2xl:mx-5 w-1 border-l" />
                    {columns && typeof columns === "function" ? (columns(dataFiltered).find(c => c.filter) ? columns(dataFiltered).filter(c => c.filter).map(c => {
                        if (c.filter.type === "select") {
                            return <Select key={c.key} options={data.map(d => d[c.key]).filter((value, index, self) => self.indexOf(value) === index)} selectedOptions={selectBar[c.id] ? selectBar[c.id] : []} setFilteredData={value => {
                                var allSelectBar = selectBar;
                                setSelectBar([])
                                if (allSelectBar[c.id]) {
                                    if (allSelectBar[c.id].find(s => s === value)) {
                                        allSelectBar[c.id] = allSelectBar[c.id].filter(s => s !== value)
                                    } else {
                                        allSelectBar[c.id].push(value)
                                    }
                                } else {
                                    allSelectBar[c.id] = [value]
                                }
                                setSelectBar(allSelectBar)
                                handleSelectFilter();

                            }} name={c.label} />
                        } else {
                            return null;
                        }
                    }) : null) : (columns.find(c => c.filter) ? columns.filter(c => c.filter).map(c => {
                        if (c.filter.type === "select") {
                            return <Select key={c.key} options={data.map(d => d[c.key]).filter((value, index, self) => self.indexOf(value) === index)} selectedOptions={selectBar[c.id] ? selectBar[c.id] : []} setFilteredData={value => {
                                var allSelectBar = selectBar;
                                setSelectBar([])
                                if (allSelectBar[c.id]) {
                                    if (allSelectBar[c.id].find(s => s === value)) {
                                        allSelectBar[c.id] = allSelectBar[c.id].filter(s => s !== value)
                                    } else {
                                        allSelectBar[c.id].push(value)
                                    }
                                } else {
                                    allSelectBar[c.id] = [value]
                                }
                                setSelectBar(allSelectBar)
                                handleSelectFilter();

                            }} name={c.label} />
                        } else {
                            return null;
                        }
                    }) : null)}
                </>
            }
            buttons={actions && actions.length ? actions : null} /> }
        <div className="w-full overflow-x-auto h-full overflow-y-auto">
            <table className="divide-y divide-gray-200 text-xs 2xl:text-sm w-full h-full">
                {!noHeader && <thead className="bg-gray-50">
                    <tr>
                        {columns &&
                            (typeof columns === "function" ?
                                columns(dataFiltered).filter(c => c.visible).map(handleHeader)
                                :
                                columns.filter(c => c.visible).map(handleHeader))
                        }
                    </tr>
                </thead>}
                <tbody className="bg-white divide-y divide-gray-200">
                    {dataFiltered &&
                        dataFiltered
                            .slice(0, count)
                            .map((item, cKey) => (
                                <tr key={cKey}>
                                    {columns &&
                                        typeof columns === "function" ?
                                        columns(dataFiltered).filter(c => c.visible).map((column, vKey) => (
                                            <td key={vKey} className="px-6 py-3 2xl:py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {column.format ? column.format(item) : item[column.id]}
                                            </td>
                                        )) :
                                        columns.filter(c => c.visible).map((column, vKey) => (
                                            <td key={vKey} className="px-6 py-3 2xl:py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                {column.format ? column.format(item) : item[column.id]}
                                            </td>
                                        ))}
                                </tr>
                            ))}
                </tbody>
            </table>
            {
                dataFiltered && dataFiltered.length > count ? (
                    <div className="my-2 2xl:my-6 mb-6 flex flex-wrap justify-center">
                        <Button
                            onClick={() => setCount(count + 30)}
                        >
                            {t("Load more")}
                        </Button>
                    </div>
                ) : (
                    ""
                )
            }
        </div >
    </>);
}

export default withTranslation()(Table);