import {
  LockClosedIcon,
  LockOpenIcon
} from "@heroicons/react/24/outline";
import React, { useEffect, useState, useContext } from "react";
import { withTranslation } from "react-i18next";
import { getUser } from "../auth/verifyAuth";
import UserCard from "../components/Card/UserCard";

// import components
import Layout from "../layout/Layout";
import { SetTrakingHubspot } from "../utils/functions/trackingHubspot";
import {
  activateAccountFromTenant,
  deactivateAccountFromTenant,
  getAllAccounts
} from "../utils/requests/account";
import { IsAdminContext } from "../layout/Horizontal/Global";
import Button from "../components/Buttons/Button";
import NotFound from "../components/Page/NotFound";
import TabsUnderline from "../components/Tabs/TabsUnderline";
import { GlobeEuropeAfricaIcon } from "@heroicons/react/24/outline";
import ModalHandleCreateUsers from "./Users/ModalHandleCreateUsers";
import LoadingSpinner from "../components/Animation/LoadingSpinner";
import ListUserCardSkeleton from "../components/LoadingComponent/ListUserCardSkeleton";

const tabs = [
  {
    id: "all",
    name: "All"
  },
  {
    id: "active",
    name: "Active"
  },
  {
    id: "inactive",
    name: "Inactive"
  }
]

function Informations({ t }) {
  const [loading, setLoading] = useState(true);
  const [openForm, setOpenForm] = useState(false);
  const isAdmin = useContext(IsAdminContext);
  const [contacts, setContacts] = useState([]);
  const [printContacts, setPrintContacts] = useState([]);
  const [currentTab, setCurrentTab] = useState("active");
  const [me, setMe] = useState(null);


  useEffect(() => {
    setLoading(true);
    SetTrakingHubspot("users");
    const curentUser = getUser();
    setMe(curentUser.username);

    getAllAccounts().then((accounts) => {
      setLoading(false);
      setContacts(accounts);
      handleTabFilter("active", accounts);
    });
  }, []);


  function refreshContacts() {
    setLoading(true);
    getAllAccounts(true).then((accounts) => {
      setLoading(false);
      setContacts(accounts);
      handleTabFilter(currentTab, accounts);
    });
  }

  function handleTabFilter(val, accounts) {
    setCurrentTab(val)
    switch (val) {
      case "active":
        if (accounts.find(a => a.activate === true)) {
          setPrintContacts(accounts.filter(a => a.activate === true))
        } else {
          handleTabFilter("all", accounts)
        }
        break;
      case "inactive":
        if (accounts.find(a => a.activate === false)) {
          setPrintContacts(accounts.filter(a => a.activate === false))
        } else {
          handleTabFilter("all", accounts)
        }
        break;
      default:
        setPrintContacts(accounts);

    }
  }

  function reset() {
    setContacts([]);
    refreshContacts();
  }

  return (
    <div>
      <ModalHandleCreateUsers openForm={openForm} setOpenForm={setOpenForm} reset={reset} contacts={contacts} />
      <Layout title="Users" actions={[<Button className="bg-[#0a7eff] text-white p-2 px-3 rounded-xl" onClick={() => { setOpenForm(true) }}>{t("Add user")}</Button>]}>
        <div className="mt-3">
          {contacts && contacts.length ? <>
            {contacts.find(a => a.activate === false) && contacts.find(a => a.activate === true) ? <TabsUnderline tabs={tabs.map(v => { v.name = t(v.name); return v; })} selectedTab={currentTab} setSelectedTab={v => handleTabFilter(v, contacts)} /> : null}
            {printContacts.length ? <>
              <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mt-3 mx-5">
                {printContacts.map((c, key) => (
                  <li key={key}>
                    <UserCard
                      active={c.activate}
                      displayName={<div className="flex">{c.displayName}{c.admin && <GlobeEuropeAfricaIcon className="ml-1 h-5 w-5" />}</div>}
                      subtitle={[c.jobTitle, c.userPrincipalName]}
                      imageUrl={c.photo}
                      buttons={isAdmin ? [
                        <div className="w-0 flex-1 flex">
                          {c.activate ? <div
                            className={me === c.userPrincipalName || c.admin ? "relative w-0 flex-1 inline-flex items-center justify-center cursor-not-allowed py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg bg-gray-200" :
                              "relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border cursor-pointer border-transparent rounded-br-lg hover:text-gray-500"}
                            onClick={() => {
                              if (me !== c.userPrincipalName && !c.admin) {
                                deactivateAccountFromTenant(c.userPrincipalName).then((res) => {
                                  if (res) {
                                    refreshContacts();
                                  }
                                })
                              }
                            }}
                          >
                            <LockClosedIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="ml-3">
                              {t("Deactivate")}
                            </span>
                          </div> : <div
                            className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                            onClick={() => {
                              activateAccountFromTenant(c.userPrincipalName).then((res) => {
                                if (res) {
                                  refreshContacts();
                                }
                              });
                            }}
                          >
                            <LockOpenIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span className="ml-3 cursor-pointer">
                              {t("Activate")}
                            </span>
                          </div>}
                        </div>,
                      ] : null}
                    />
                  </li>
                ))}
              </ul></> : (loading ? <div className="h-full w-full relative">
                <div>
                  <ListUserCardSkeleton length={3} />
                </div>
              </div> : <NotFound />)}</> : (loading ? <div className="h-full w-full relative">
                <div>
                  <ListUserCardSkeleton length={3} />
                </div>
              </div> : <NotFound />)}
        </div>
      </Layout>
    </div>
  );
}

export default withTranslation()(Informations);
