import { getAccessToken } from "../../auth/verifyAuth";

async function getFactures() {
    let oldFactures = localStorage.getItem("factures");
    if (oldFactures) { oldFactures = JSON.parse(oldFactures); }

    if (!oldFactures || !oldFactures.data || ((new Date(oldFactures.time)).getDate() !== (new Date()).getDate())) {
        var accessToken = await getAccessToken();
        return fetch(window.location.origin + "/api/v1.0/factures",
            {
                method: "GET",
                headers: {
                    "my-Authorization": `Bearer ${accessToken}`
                }
            }
        )
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then((res) => {
                if (res) {
                    localStorage.setItem("factures", JSON.stringify({ "data": res, "time": new Date() }))
                    return res;
                }
            })
    } else {
        return oldFactures.data
    }
}

 export {
    getFactures
 }