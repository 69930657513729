import { withTranslation } from "react-i18next"
import PageTitle from "../components/Text/PageTitle";

function Layout({ t, title, type, children, actions, loading }) {

    return <div className="w-full h-full">
        <div className="md:flex md:flex-row md:justify-between content-center items-center m-5 mt-7">
            {loading ? <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-64"></div>
                : <PageTitle>{t(title)}</PageTitle>}
            <div className="flex flex-row">
                {actions && actions.map((a, k) => <div key={k}>{a}</div>)}
            </div>
        </div>
        {children}
    </div>
}

export default withTranslation()(Layout);