import { getAccessToken, getAccessTokenGraph } from "../../auth/verifyAuth";

async function getMe() {
  var accessToken = await getAccessTokenGraph();

  return fetch("https://graph.microsoft.com/beta/me", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
}

async function getUserPhoto() {
  var accessToken = await getAccessTokenGraph();

  return fetch("https://graph.microsoft.com/beta/me/photo/$value", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
}

async function getDomaine() {
  var accessToken = await getAccessTokenGraph();

  return fetch("https://graph.microsoft.com/v1.0/domains", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
}

async function getIfRegistered() {
  var accessToken = await getAccessToken();

  return fetch(window.location.origin + "/api/v1.0/rights", {
    method: "GET",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`
    },
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else {
      return false;
    }
  });
}

export { getUserPhoto, getIfRegistered, getDomaine, getMe};