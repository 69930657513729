
function StatisticsCardSkeleton({ Icon, description }) {
    return <div className="border border-dashed rounded-xl p-4 animate-pulse py-4">
        <Icon className="h-12 w-12 rounded-full p-3 bg-gray-200 text-gray-400" />
        <div className="pt-[2rem]" style={{ fontSize: "32px", fontWeight: "900" }}>
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-16"></div>
        </div>
        <div className="text-gray-500 text-lg font-medium pt-4">
            {description}
        </div>
    </div>
}

export default StatisticsCardSkeleton;