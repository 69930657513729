import React, { useEffect, useState } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getExpandedRowModel,
    flexRender,
} from '@tanstack/react-table'
import { withTranslation } from "react-i18next";
import { ChevronDownIcon, ChevronRightIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, PauseCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import ReactTooltip from "react-tooltip";
import { getAllTenants, getTenant } from "../requests/tenant";
import FormIncreaseAmountLicence from "../../pages/uses/csp/FormIncreaseAmountLicence";

function TableExpandableLicences({ data, t }) {
    const [tenant, setTenant] = useState({});
    const [selectedLicence, setSelectedLicence] = useState({});
    const [openForm, setOpenForm] = useState(false);

    useEffect(() => {
        getAllTenants().then((tenants) => {
            if (tenants && tenants.length) {
                setTenant(tenants[0]);
            } else {
                getTenant().then((t) => {
                    setTenant(t);
                });
            }
        });
    }, [])

    const columns = React.useMemo(() => [
        {
            header: t("Name"),
            accessorKey: "subscriptionOfferName",
            cell: ({ row, getValue }) => {
                return row.original.subRows ? <div className="my-2 ml-1">{row.getCanExpand() && row.original.subRows.length > 1 ? (
                    <button
                        {...{
                            onClick: row.getToggleExpandedHandler(),
                            style: { cursor: 'pointer' },
                        }}
                    >
                        <div className="flex">
                            {row.getIsExpanded() ? <ChevronDownIcon className="h-3 w-3 mr-2 mt-1" /> : <ChevronRightIcon className="h-3 w-3 mr-2 mt-1" />}
                            {getValue()}
                        </div>
                    </button>
                ) : <div className="flex ml-5">{getValue()}</div>}</div> : <div className="ml-5 bg-white shadow rounded-xl text-sm my-1 p-2 text-center w-64">{getValue()}</div>
            }
        },
        {
            header: t("Quantité"),
            accessorKey: "subscriptionQuantity",
            cell: ({ row, getValue }) => <div className="text-right font-bold flex w-16 justify-end" >
                {getValue()}
                {row.original.subRows ? <div className="ml-1 cursor-pointer" onClick={() => { setSelectedLicence(row.original); setOpenForm(true); }}>
                    <div div data-tip data-for={row.id + "subscriptionQuantity"} >
                        <PlusCircleIcon className="h-5 w-5 text-gray-600" />
                    </div>
                    <ReactTooltip id={row.id + "subscriptionQuantity"} type="light">
                        <span>{t("Si vous souhaitez augmenter le nombre de licences, cliquez ici.")}</span>
                    </ReactTooltip>

                </div > : null}
            </div>
        },
        {
            header: t("Fin de contrat"),
            accessorKey: "subscriptionCommitmentEndDate",
            cell: ({ row, getValue }) => {
                var date = new Date(getValue())
                return date instanceof Date && !isNaN(date.valueOf()) ? <span>
                    {(date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear())}
                </span> : null
            }
        },
        {
            header: t("Durée"),
            accessorKey: "subscriptionTermDuration",
            cell: ({ row, getValue }) => <>{getValue()}</>
        },
        {
            header: t("Statut"),
            accessorKey: "subscriptionStatus",
            cell: ({ row, getValue }) => {
                if (row.original.subRows) {
                    switch (getValue()) {
                        case "Active":
                            return <CheckCircleIcon className="h-5 w-5 text-green-500 ml-3" />;
                        case "Deleted":
                            return <XCircleIcon className="h-5 w-5 text-red-500 ml-3" />
                        default:
                            return <PauseCircleIcon className="h-5 w-5 text-yellow-500 ml-3" />
                    }
                } else {
                    return null;
                }
            }
        }
    ], [tenant])

    const [expanded, setExpanded] = React.useState({})

    const table = useReactTable({
        data,
        columns,
        state: {
            expanded,
        },
        onExpandedChange: setExpanded,
        getSubRows: row => row.subRows,
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        debugTable: false,
    })

    return (<div>
        <FormIncreaseAmountLicence openForm={openForm} setOpenForm={setOpenForm} licence={selectedLicence} />
        <div className="w-full overflow-x-auto h-full overflow-y-auto">
            <table className="divide-y divide-gray-200 text-xs 2xl:text-sm w-full h-full">
                <thead className="bg-gray-100 ">
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id} className="text-left h-10">
                            {headerGroup.headers.map(header => (
                                <th key={header.id} className={header.id === "subscriptionOfferName" ? "pl-7 uppercase text-gray-500" : "uppercase text-gray-500"}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className="bg-white">
                    {table.getRowModel().rows.map(row => {
                        return (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => {
                                    const isSubRow = !(row.original.subRows);
                                    return (
                                        <td key={cell.id} className={isSubRow ? "bg-gray-100" : "text-sm border-b border-gray-200"}>
                                            {flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div></div>);
}

export default withTranslation()(TableExpandableLicences);
