import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import translationFr from "./fr.json";
import translationEn from "./en.json";

i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        debug: process.env.NODE_ENV === "development",
        lng: localStorage ? localStorage.getItem('Language') : "fr",//setLanguage(),
        fallbackLng: ["en","fr"], // use en if detected lng is not available
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        resources: {
            fr: {
                translations: translationFr
            },
            en: {
                translations: translationEn
            }
        },
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations"
    });

export default i18n;
