import React, { useEffect, useState } from "react";
import { ArrowLeftOnRectangleIcon, Bars3Icon } from "@heroicons/react/24/outline";
import { withTranslation } from "react-i18next";
import { getUserPhoto } from "../../../utils/requests/user";
import { logout } from "../../../auth/verifyAuth";
import { useLocation, useNavigate } from "react-router";

function HeaderMobile({ t, setMobileMenuOpen }) {
  const [photo, setPhoto] = useState({});

  useEffect(() => {
    getUserPhoto().then((photoAPI) => {
      setPhoto(photoAPI);
    });
  }, []);

  let navigate = useNavigate();
  let location = useLocation();

  return (
    <div className="sticky top-0 z-10 flex h-16 bg-white shadow md:hidden">
      <div className="pr-4 flex justify-between w-full">
        <button
          type="button"
          className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">{t("Open sidebar")}</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="mx-4 flex items-center md:ml-6">
          <div className="ml-3 relative">
            <div>
              <div
                onClick={() => {
                  if (location.pathname === "/") {
                    logout();
                  } else {
                    navigate("/");
                    logout();
                  }
                }}
                className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {photo ? (
                  <img className="h-8 w-8 rounded-full" src={photo} alt="" />
                ) : (
                  <ArrowLeftOnRectangleIcon className="text-gray-500 hover:text-gray-300 flex-shrink-0 h-6 w-6" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(HeaderMobile);
