import { getAccessToken, getAccessTokenGraph } from "../../auth/verifyAuth";
import { getMe } from "./user";

async function getAllAccounts() {
  var accessToken = await getAccessToken();
  return fetch(window.location.origin + "/api/v1.0/accounts", {
    method: "GET",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((list) => {
      if (!list || !list.length) {
        return null;
      }
      return getInformationsByEmails(list.map((l) => l.mail)).then(
        (informations) => {
          var promises = [];
          informations.forEach((inf) => {
            promises.push(
              getUserPhotoById(inf.id)
                .then((res) => {
                  inf.photo = res;
                  return inf;
                })
                .catch(() => {
                  return inf;
                })
            );
          });
          return Promise.all(promises).then((acccounts) => {
            acccounts = acccounts.map(a => {
              if (list.find(l => l.mail === a.userPrincipalName)) {
                a.activate = list.find(l => l.mail === a.userPrincipalName).isActive;
                a.admin = list.find(l => l.mail === a.userPrincipalName).isAdmin;
              }
              return a;
            })

            return acccounts;
          });
        }
      );
    });

}

async function getInformationsByEmails(mails) {
  var accessToken = await getAccessTokenGraph();
  var url = "https://graph.microsoft.com/beta/users?$filter=userPrincipalName in (";
  mails.forEach((mail, index) => {
    if (index === mails.length - 1) {
      url += "'" + mail + "'";
    } else {
      url += "'" + mail + "',";
    }
  });
  url += ")&$select=userPrincipalName,displayName,id,jobTitle";
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
    .then((res) => {
      if (res) {
        return res.value;
      }
    });
}

async function getUserPhotoById(id) {
  var accessToken = await getAccessTokenGraph();

  return fetch(
    "https://graph.microsoft.com/beta/users/" + id + "/photo/$value",
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).then((res) => {
    if (res.ok) {
      return res.json();
    }
  });
}

async function deactivateAccountFromTenant(mail) {
  var accessToken = await getAccessToken();

  return fetch(window.location.origin + "/api/v1.0/accounts/deactivate/" + mail, {
    method: "PATCH",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
    },
  }).then((res) => {
    if (res.ok) {
      return true;
    } else {
      return false;
    }
  });
}

async function activateAccountFromTenant(mail) {
  var accessToken = await getAccessToken();

  return fetch(window.location.origin + "/api/v1.0/accounts/activate/" + mail, {
    method: "PATCH",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
    },
  }).then((res) => {
    if (res.ok) {
      return true;
    } else {
      return false;
    }
  });
}

async function postAccountHS(mail) {
  var accessToken = await getAccessToken();
  return fetch(window.location.origin + "/api/v1.0/accounts/" + mail, {
    method: "POST",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
    },
  });
}

async function postAccount(contact) {
  var accessToken = await getAccessToken();
  return fetch(window.location.origin + "/api/v1.0/accounts", {
    method: "POST",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(contact)
  });
}

async function getAllAccountFromHubspot() {
  var accessToken = await getAccessToken();
  return fetch(window.location.origin + "/api/v1.0/HSaccounts", {
    method: "GET",
    headers: {
      "my-Authorization": `Bearer ${accessToken}`,
    },
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
    })
}

export { getAllAccounts, activateAccountFromTenant, postAccountHS, deactivateAccountFromTenant, postAccount, getAllAccountFromHubspot };
