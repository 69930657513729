import { getAccessToken } from "../../auth/verifyAuth";

async function getBlogPostsByType(type) {
    let oldBlogByType = localStorage.getItem("blogs_" + type);
    if (oldBlogByType) {oldBlogByType = JSON.parse(oldBlogByType);}

    if (!oldBlogByType || !oldBlogByType.data || ((new Date(oldBlogByType.time)).getDate() !== (new Date()).getDate())) {
    var accessToken = await getAccessToken();
    return fetch(window.location.origin + "/api/v1.0/blog/" + type,
        {
            method: "GET",
            headers: {
                "my-Authorization": `Bearer ${accessToken}`
            }
        }
    )
        .then((res) => {
            if (res.ok) {
                return res.json();
            }
        })
        .then((res) => {
            if (res) {
                localStorage.setItem("blogs_" + type, JSON.stringify({"data" : res, "time" : new Date()}))
                return res;
            }
        })
    } else {
        return oldBlogByType.data;
    }
}

async function getAllPosts() {
    // let oldBlog = localStorage.getItem("blogs");
    // if (oldBlog) {oldBlog = JSON.parse(oldBlog);}

    // if (!oldBlog || !oldBlog.data || ((new Date(oldBlog.time)).getDate() !== (new Date()).getDate())) {
    // var accessToken = await getAccessToken();
    // return fetch(window.location.origin + "/api/v1.0/blog",
    //     {
    //         method: "GET",
    //         headers: {
    //             "my-Authorization": `Bearer ${accessToken}`
    //         }
    //     }
    // )
    //     .then((res) => {
    //         if (res.ok) {
    //             return res.json();
    //         }
    //     })
    //     .then((res) => {
    //         if (res) {
    //             localStorage.setItem("blogs", JSON.stringify({"data" : res, "time" : new Date()}))
    //             return res;
    //         }
    //     })
    // } else {
    //     return oldBlog.data;
    // }
}

export { getBlogPostsByType, getAllPosts };
