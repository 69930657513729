import { getAccessToken } from "../../auth/verifyAuth";

async function getProjects() {
    let oldProjects = localStorage.getItem("projects");
    if (oldProjects) { oldProjects = JSON.parse(oldProjects); }

    if (!oldProjects || !oldProjects.data || ((new Date(oldProjects.time)).getDate() !== (new Date()).getDate())) {
        var accessToken = await getAccessToken();
        return fetch(window.location.origin + "/api/v1.0/projects",
            {
                method: "GET",
                headers: {
                    "my-Authorization": `Bearer ${accessToken}`
                }
            }
        )
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then((res) => {
                if (res) {
                    localStorage.setItem("projects", JSON.stringify({ "data": res, "time": new Date() }))
                    return res;
                }
            })
    } else {
        return oldProjects.data
    }
}

 export {
    getProjects
 }