import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";

// import components
import { getAllLicences } from "../../../utils/requests/licenses";
import { SetTrakingHubspot } from "../../../utils/functions/trackingHubspot";
import LicenceType from "./LicenceType";
import LicenceTypeTableSkeleton from "../../../components/LoadingComponent/LicenceTypeTableSkeleton";

function Licences({ t }) {
    const [defaultLicences, setDefaultLicences] = useState([]);
    const [loading, setLoading] = useState(0);

    useEffect(() => {
        SetTrakingHubspot("licences");
        getAllLicences().then(licencesAPI => {
            setLoading(1);
            licencesAPI = licencesAPI.map(l => {
                l.subRows = l.products;
                l.subRows = l.subRows.map(sr => {
                    sr.subscriptionOfferName = sr.nom;
                    return sr;
                })
                return l;
            })
            setDefaultLicences(licencesAPI);
        })
    }, []);

    return loading ? (<div>
        <div className="mt-2"></div>
        <LicenceType licencesAPI={defaultLicences.filter(l => !l.subscriptionOfferName.includes("tudent") && !l.subscriptionOfferName.includes("lumni")).sort((a, b) => {
            if (b.subscriptionQuantity < a.subscriptionQuantity)
                return -1;
            if (b.subscriptionQuantity > a.subscriptionQuantity)
                return 1;
            return 0;
        })} title={"Staff"} key="staff" />
        <div className="mt-4"></div>
        <LicenceType licencesAPI={defaultLicences.filter(l => l.subscriptionOfferName.includes("tudent")).sort((a, b) => {
            if (b.subscriptionQuantity < a.subscriptionQuantity)
                return -1;
            if (b.subscriptionQuantity > a.subscriptionQuantity)
                return 1;
            return 0;
        })} title={"Student"} key="student" />
        <div className="mt-4"></div>
        <LicenceType licencesAPI={defaultLicences.filter(l => l.subscriptionOfferName.includes("lumni")).sort((a, b) => {
            if (b.subscriptionQuantity < a.subscriptionQuantity)
                return -1;
            if (b.subscriptionQuantity > a.subscriptionQuantity)
                return 1;
            return 0;
        })} title={"Alumni"} key="alumni" />
    </div>
    ) : <div>
        <LicenceTypeTableSkeleton title={"Staff"} />
        <LicenceTypeTableSkeleton title={"Student"} />
        <LicenceTypeTableSkeleton title={"Alumni"} />
    </div>;
}

export default withTranslation()(Licences);
