import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Disclosure } from '@headlessui/react'

// import components
import Table from "../../components/Tables/Table";
import { getTMA } from "../../utils/requests/tma";
import columnsTMA from "../../utils/tables/columnsTMA";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import FormIncreaseTMA from "./FormIncreaseTMA";
import Button from "../../components/Buttons/Button";
import { PlusIcon } from "@heroicons/react/24/outline";
import ListCardSkeleton from "../../components/LoadingComponent/List";

function TMA({ t }) {
    const [loading, setLoading] = useState(0)
    const [tma, setTMA] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [tmaType, setTMAType] = useState("");

    useEffect(() => {
        setLoading(0)
        getTMA().then(tmaAPI => {
            setLoading(1);
            setTMA(tmaAPI);
        })
    }, [])

    return (<div className="mt-4">
        <FormIncreaseTMA openForm={openForm} setOpenForm={setOpenForm} tmaType={tmaType} />
        {loading ? (tma && tma.length > 0 ?
            tma.map(ta => {
                return <Disclosure defaultOpen={tma.length === 1 ? true : false}>
                    {({ open }) => (
                        <>

                            <Disclosure.Button className="rounded-lg py-2 px-4 flex justify-between w-full bg-[#0A2472] bg-opacity-10  hover:bg-opacity-40 text-[#0A2472] font-bold" >
                                <div>
                                    {ta.name}
                                </div>
                                <div className="flex">
                                    <span className="bg-[#0A2472] text-white font-bold text-sm rounded-full py-1 px-2 ml-2">
                                        {t("Left time :")} {ta.timeLeft ? Math.round(ta.timeLeft) : "0"}h
                                    </span>
                                    <Button className="bg-white text-gray-700 p-1 rounded-xl mx-2" onClick={() => { setTMAType(ta.name); setOpenForm(true) }}><PlusIcon className="h-5 w-5" /></Button>
                                    <ChevronUpIcon className={open ? "rotate-180 transform w-7 h-7" : "w-7 h-7"} />
                                </div>
                            </Disclosure.Button>
                            <Disclosure.Panel className="m-4 border rounded-lg">
                                <div>
                                    <Table key="tma" data={ta.tasks} columns={() => columnsTMA(t)} noHeader={true} noToolbar={true} />
                                </div>
                            </Disclosure.Panel>
                        </>)}
                </Disclosure>
            }) : <div className="border-dashed border rounded-lg p-3 my-3 text-center text-sm text-gray-500 italic">
                {t("No TMA available")}
            </div>
        ) : <ListCardSkeleton length={5} />}
    </div>

    );
}

export default withTranslation()(TMA);
