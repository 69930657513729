import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import SectionTitle from "../components/Text/SectionTitle";

import Layout from "../layout/Layout";
import { SetTrakingHubspot } from "../utils/functions/trackingHubspot";
import Projects from "./TMAProjects/Projects";
import TMA from "./TMAProjects/TMA";

function TMAProjects({ t }) {
  useEffect(() => {
    SetTrakingHubspot("tmaprojects")
  }, [])


  return (
    <Layout title="TMA and Project">
      <div className="mx-5">
        <SectionTitle>{t("Projects")}</SectionTitle>
        <Projects />
      </div>
      <div className="mx-5 mt-5">
        <SectionTitle>{t("TMA")}</SectionTitle>
        <TMA />
      </div>
    </Layout>
  );
}

export default withTranslation()(TMAProjects);
