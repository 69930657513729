import React from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import SectionTitle from "../../components/Text/SectionTitle";
import ModalRecommandations from "../../pages/uses/csp/ModalRecommandations";

const tags = ["identity", "collaboration", "device"]

function ListSecurity({ subscriptions, recommandations, t, secureScore }) {
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [selectedLicence, setSelectedLicence] = useState(false);

    useEffect(() => {
        var categoriesTmp = [];
        var dataTmp = [];
        tags.forEach(c => {
            var list = [];
            if (subscriptions && subscriptions.length) {
                subscriptions.forEach(r => {
                    if (r.tag && r.tag.includes("security") && r.tag.includes(c)) {
                        r.isPossessed = true;
                        list.push(r);
                    }
                })
            }

            if (recommandations && recommandations.length) {
                recommandations.forEach(r => {
                    if (r.tag && r.tag.includes("security") && r.tag.includes(c)) {
                        r.isPossessed = false;
                        list.push(r);
                    }
                })
            }
            dataTmp.push(list);

            if (list.length && list.length && !categoriesTmp.includes(c)) {
                categoriesTmp.push(c)
            }
        });
        setData(dataTmp);
        setCategories(categoriesTmp);
    }, [subscriptions, recommandations])

    return <div className="grid grid-cols-1 xl:grid-cols-3 xl:space-x-6 space-y-6 xl:space-y-0">
        <ModalRecommandations licence={selectedLicence} openForm={openForm} setOpenForm={setOpenForm} />
        {categories.map((c, index) => data[index].length ? <div key={c + index} className="bg-white p-5 overflow-hidden border-dashed border rounded-xl">
            <SectionTitle actions={[<div onClick={()=>{window.open("https://security.microsoft.com/securescore?viewid=actions", '_blank');}} className="cursor-pointer bg-[#600AFF] text-white h-12 w-12 rounded-full text-center pt-3 font-bold">{secureScore ? <>{secureScore[c]}%</> : <>N/A</>}</div>]}>
                {t(c)}
            </SectionTitle>
            {
                data[index].sort((a, b) => a.order - b.order).map((d) => {
                    var link = null;
                    if (d && d.isPossessed && d.applink) {
                        link = d.applink;
                    } else if (d && !d.isPossessed && d.link) {
                        link = d.link;
                    }
                    return <div key={c + d.id} className="w-full py-4 text-sm text-gray-500 flex" onClick={() => {
                        if (d && d.isPossessed && d.applink) {
                            window.open(link, '_blank');
                        } else if (d && !d.isPossessed && d.link) {
                            setSelectedLicence(d);
                            setOpenForm(true);
                        }
                    }}>
                        <div key={"checked" + d.id} className="mr-5">
                            {(d.isPossessed) ? <>
                                <CheckCircleIcon className="h-5" style={{ color: "#0aff0a" }} />
                            </> : <>
                                <XCircleIcon className="h-5" style={{ color: "#ff0a33" }} />
                            </>
                            }
                        </div>
                        <div key={"text" + d.id} className={link ? "cursor-pointer text-md text-gray-800" : "text-md text-gray-500"}>{d.nom}</div>

                    </div>
                })
            }
        </div > : "")
        }
    </div >
}

export default withTranslation()(ListSecurity)
