export default function SectionTitle({ children, actions }) {
    return (<div>
    <div className="block w-full">
        <div className="flex justify-between items-center align-center w-full">
            <h1 className="text-gray-700" style={{fontSize:"20px", fontWeight:"700", letterSpacing:"-1px"}}>
                {children}
            </h1>
            {actions ? <div className="self-center flex space-x-2">{actions.map((a,key) => <span key={key}>{a}</span>)}</div> : null}
        </div>
    </div> 
</div>)
  }