import ProgressBar from "@ramonak/react-progress-bar";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Card from "../../components/Card/Card";
import ListCardSkeleton from "../../components/LoadingComponent/List";

// import components
import { getProjects } from "../../utils/requests/projects";

function Projects({ t }) {
  const [loading, setLoading] = useState(0)
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    setLoading(0)
    getProjects().then(projectsAPI => {
      setLoading(1);
      setProjects(projectsAPI);
    })
  }, [])

  return (
    <div>
      {loading ? (projects && projects.length > 0 ? projects.map((p, k) => <Card key={k}>
        <div className="grid grid-cols-3">
          <div className="col-span-2">
            <div className="font-normal">{p.name}</div>
            <div className="text-gray-400">{p.type}</div>
          </div>
          <div>
            <ProgressBar bgColor="#349906" completed={p.totaltask > 0 ? Math.round(p.donetask * 100 / p.totaltask) : 0} />
          </div>
        </div>
      </Card>) : <div className="border-dashed border rounded-lg p-3 my-3 text-center text-sm text-gray-500 italic">
        {t("No Projects available")}
      </div>) : <ListCardSkeleton length={1} />}
    </div>
  );
}

export default withTranslation()(Projects);
