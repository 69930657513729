import { withTranslation } from 'react-i18next';

const WIPImg = require("../../assets/icons/techny-workflow.png")

function NotFound({ t }) {

    return (
       <div className="py-10 text-center flex items-center justify-center flex-col">
        <img src={WIPImg} alt="wip-icon" className="self-center"/>
        <div>{t("Not Found")}</div>
       </div>
    )
}

export default withTranslation()(NotFound);