import React from "react";
import { withTranslation } from "react-i18next";

import SectionTitle from "../../components/Text/SectionTitle";

function LicenceTypeTableSkeleton({ t, title }) {


    return <div>
        <SectionTitle><div className="ml-5">{t(title)}</div></SectionTitle>
        <div className="mt-4">
            <div className="w-full overflow-x-auto h-full overflow-y-auto">
                <table className="divide-y divide-gray-200 text-xs 2xl:text-sm w-full h-full">
                    <thead className="bg-gray-100 ">
                        <tr key="" className="text-left h-10">
                            <th className="uppercase text-gray-500">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24 ml-1"></div>
                            </th>
                            <th className="uppercase text-gray-500">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                            </th>
                            <th className="uppercase text-gray-500">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                            </th>
                            <th className="uppercase text-gray-500">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                            </th>
                            <th className="uppercase text-gray-500">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-24"></div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        <tr>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12 my-2 ml-1"></div>
                            </td>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div>
                            </td>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div>
                            </td>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div>
                            </td>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12 my-2 ml-1"></div>
                            </td>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div>
                            </td>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div>
                            </td>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div>
                            </td>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div>
                            </td>
                        </tr>
                        <tr>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12 my-2 ml-1"></div>
                            </td>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div>
                            </td>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div>
                            </td>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div>
                            </td>
                            <td className="text-sm border-b border-gray-200">
                                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-12"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div >;
}

export default withTranslation()(LicenceTypeTableSkeleton);
