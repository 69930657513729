import React from "react";
import { withTranslation } from "react-i18next";
import SectionTitle from "../Text/SectionTitle";

const tags = ["identity", "collaboration", "device"]

function ListSecuritySkeleton({ t }) {
    return <div className="grid grid-cols-1 xl:grid-cols-3 xl:space-x-6 space-y-6 xl:space-y-0 animate-pulse">
        {tags.map((c, index) => <div key={c + index} className="bg-white p-5 overflow-hidden border-dashed border rounded-xl">
            <SectionTitle
                actions={[<div className="cursor-pointer bg-gray-400 text-white h-12 w-12 rounded-full text-center pt-3 font-bold"></div>]}>
                {t(c)}
            </SectionTitle>
            {
                Array.apply(0, Array(3)).map(function (_, b) { return b + 1; }).map((d) => <div key={c + d.id} className="w-full py-4 text-sm text-gray-500 flex">
                    <div key={"checked" + d.id} className="mr-5">
                        <div className="h-5 w-5 bg-gray-200 rounded-full" />
                    </div>
                    <div key={"text" + d.id} className="text-md text-gray-500">
                        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-64"></div>
                    </div>
                </div>
                )
            }
        </div >)}
    </div >
}

export default withTranslation()(ListSecuritySkeleton)
