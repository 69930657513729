import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ConsommationAzureBar({ t, azureConsommation, height, width }) {
  const [data, setData] = useState(null);
  const [options, setOptions] = useState(null);

  useEffect(() => {
    const optionsG = {
      responsive: true,
      scales: {
        x: {
          stacked: true
        },
        y: {
          stacked: true,
          callback: function (value) {
            return `${value}€`
          }
        }
      },
      plugins: {
        legend: null,
      },
    };

    setOptions(optionsG)

    if (azureConsommation && azureConsommation.length) {
      const dataG = {
        labels: azureConsommation.map(i => i.month + "/" + i.year),
        datasets: [
          {
            label: t("Consumption"),
            data: azureConsommation.map((i) => i.cost),
            backgroundColor: "#0a7eff",
          }
        ],
      };

      setData(dataG);
    }
  }, [azureConsommation, t]);

  return data && options ? <Bar data={data} options={options} height={height} width={width} /> : <>{t("You don't have any azure consumption. Start your consumption by clicking here.")}</>;

}

export default withTranslation()(ConsommationAzureBar);
