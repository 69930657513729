function StatisticsCard({ Icon, color, amount, description }) {
    return <div className="border border-dashed rounded-xl p-4">
        <Icon className="h-12 w-12 rounded-full p-3" style={{ backgroundColor: color+"20", color: color }} />
        <div className="pt-2" style={{ fontSize: "32px", fontWeight: "900" }}>
            {amount}
        </div>
        <div className="text-gray-500 text-lg font-medium">
            {description}
        </div>
    </div>
}

export default StatisticsCard;