// import all pages 
import Dashboard from "../../pages/Dashboard";
import AzureConsommation from "../../pages/AzureConsommation";
import Users from "../../pages/Users";


import { HomeIcon, ChartBarIcon, BriefcaseIcon, UsersIcon, NewspaperIcon, ClockIcon } from '@heroicons/react/24/outline'
import Bills from "../../pages/Bills";
import TMAProjects from "../../pages/TMAProjects";
import CSP from "../../pages/uses/csp/CSP";
import TenantInformation from "../../pages/TenantInformation";

const routes = [
    {
        path: "/",
        module : "dashboard",
        element: <Dashboard/>,
        exact : true,
        isMenuIcon : false,
        logo : HomeIcon,
        name : "Dashboard"
    },
    {
        path: "/csp",
        module : "csp",
        element: <CSP/>,
        exact : true,
        isMenuIcon : true,
        logo : BriefcaseIcon,
        name : "CSP"
    },
    {
        path: "/azure",
        module : "azure",
        element: <AzureConsommation/>,
        exact : true,
        isMenuIcon : true,
        logo : ChartBarIcon,
        name : "Azure"
    },
    {
        path: "/bills",
        module : "bills",
        element: <Bills/>,
        exact : true,
        isMenuIcon : false,
        logo : NewspaperIcon,
        name : "Account"
    },
    {
        path: "/tma-and-projects",
        module : "tma-and-projects",
        element: <TMAProjects/>,
        exact : true,
        isMenuIcon : false,
        logo : ClockIcon,
        name : "TMA and Project"
    },
    {
        path: "/users",
        module : "users",
        element: <Users/>,
        exact : true,
        isMenuIcon : false,
        logo : UsersIcon,
        name : "Users"
    },
    {
        path: "/informations",
        module : "informations",
        element: <TenantInformation/>,
        exact : true,
        isMenuIcon : false
    }
]

export default routes;