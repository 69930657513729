import { getAccessToken } from "../../auth/verifyAuth";

async function getTMA() {
    let oldTMA = localStorage.getItem("tma");
    if (oldTMA) { oldTMA = JSON.parse(oldTMA); }

    if (!oldTMA || !oldTMA.data || ((new Date(oldTMA.time)).getDate() !== (new Date()).getDate())) {
        var accessToken = await getAccessToken();
        return fetch(window.location.origin + "/api/v1.0/tma",
            {
                method: "GET",
                headers: {
                    "my-Authorization": `Bearer ${accessToken}`
                }
            }
        )
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }
            })
            .then((res) => {
                if (res) {
                    localStorage.setItem("tma", JSON.stringify({ "data": res, "time": new Date() }))
                    return res;
                }
            })
    } else {
        return oldTMA.data
    }
}

 export {
    getTMA
 }