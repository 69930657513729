import React from "react";
import { withTranslation } from "react-i18next";

function FiltersBar({ components, buttons }) {
    return (
        <div className="px-6 2xl:px-8 py-1 2xl:py-2 flex justify-between border-b-2">
            <div className="flex flex-row">
                {components}
            </div>
            {buttons &&
                <>
                    <div className="flex flex-row">
                        {buttons.map((button) => button)}
                    </div>
                </>}
        </div>
    );
}

export default withTranslation()(FiltersBar);
